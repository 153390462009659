import React, { Component } from 'react'
import { getLocalStorageItem } from '../../helpers/helpers';
import { Link } from 'react-router-dom';
import mainbuildingPopup from '../../components/map/assets/mainbuildingPopup.svg';

export default class GalleriesPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			galleriesPopup: false,
		}
	}

	onClose = e => {
		this.props.onClose && this.props.onClose(e)
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}

	toggleGalleriesPopup() {
		this.setState({
			galleriesPopup: !this.state.galleriesPopup,
		})
	}

	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " hint-popup-container"}>
				<div className="h-p-box-container">
					<div className="hpb-inner-container">
						<div className="hpb-close-button">
						</div>
						{/* Body Section */}
						<div className="hpb-body-container">
							{/* Title */}
							<div className="hpdb-inner-container hpdb-title-container">
								AEROSPACE HISTORY TIME!
							</div>
							{/* Image */}
							{
								<div className="hpdb-inner-container hpdb-image-container">
									<div className="hpdbi-image">
										<img src={mainbuildingPopup} alt=''/>
									</div>
								</div>
							}
							{/* Description */}
							{
								<div className="hpdb-inner-container hpdb-description-container">
									It's time to learn about aviation history!
									<br></br>
									Headover to the Aerospace Galleries to continue your adventure.
								</div>
							}
							{/* Button */}
							<div className="hpdb-inner-container hpdb-button-container">
								<button className="hpdb-button" onClick={e => { this.onClose(e) }}>
									<Link to={"/game-menu?era=1"} style={{ color: 'white' }}>
										OK
									</Link>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}