import React, { Component } from "react";
import './playerInfo.css';
import Settings from '../../images/settings-icon.svg';
import Back from "../../components/backButton/back";
import NextButton from '../../images/next-button.svg';
import game from '../global-var'
import { GameContext } from "../../context/GameContext";
import { getLocalStorageItem, setLocalStorageItem } from "../../helpers/helpers";
import Accessibility from '../../components/accessibility/accessibility'

export default class PlayerInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			player: getLocalStorageItem('player'),
			isOpen: false,
			isEmpty: false,
		};
	}
	handleChange = (event) => {
		if (!this.state.player) {
			this.setState({
				isEmpty: !this.state.isEmpty,
			})
		} else {
			this.props.history.push('/character');
		}
	}

	handleChangeFont = (event) => {
		event.preventDefault(); //Prevents full refresh
		let elements = Array.from(document.getElementsByClassName("font-btn"));
		elements.map(element => {
			if (element === event.currentTarget) {
				element.style.borderBottom = '3px solid #1067AB';
			} else (element.style.border = 'none')
			return element;
		})
		setLocalStorageItem('fontSize', event.currentTarget.value);
		console.log(game);

	}

	setName(displayName) {
		this.context.setPlayer(displayName)
	}

	state = {
		show: false
	};
	showModal = e => {
		this.setState({show: !this.state.show})
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	render() {
		var placeholder = ((game.playerName) != null) ? game.playerName : "Type your name here"
		const isEmptyPlayer = this.state.isEmpty;

		const EmptyPlayer = () => {
			return (
				<div className="hint-popup-container">
					<div className="popup-container">
						<div className="popup-inner-container">
							<div className="popup-content">
								DON'T FORGET TO ADD YOUR NAME
							</div>
							<button
								onClick={this.handleChange}
								className="basic-btn a-save-btn"
							>
								<h3>OK</h3>
							</button>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " container p-info-container"} > {/* Page Container */}
				{
					isEmptyPlayer === true &&
					<EmptyPlayer />
				}
				{/* Back Button */}
				<div className="back-button">
					<Back className="back-button" />
				</div>

				<Accessibility onClose={this.showModal} show={this.state.show} />

				{/* Body Container */}
				<div className="player-inner-container">
					{/* Title Section  */}
					<div className="p-i-title-container">
						Player Settings
					</div>


					<form className="p-i-form">
						{/* Username Section */}
						<div className="p-i-input-container">
							<div className="p-i-description-container">
								Add your name or a nickname to start the game. Your chosen name will display on the leaderboard at the end!
							</div>
							<label htmlFor="name-input">
								Name <span>*</span>
							</label>
							<input
								type="text"
								name="userName"
								value={this.state.player}
								placeholder={placeholder}
								onBlur={(e) => this.setName(e.target.value)}
								onChange={e => this.setState({ player: e.target.value })}
								required
							/>
						</div>
					</form>
					{/* Accessibility Settings */}
					<div className="p-i-accessibility-container">
						{/* Description Section */}
						<div className="p-i-a-description">
							Adjust font size in accessibility settings.
						</div>
						{/* Accessibility Button */}
						<div className="p-i-a-button-container">
							<button
								// to="/popup-accessibility"
								onClick={e => {this.showModal()}}
								className="p-i-a-button">
								<div className="p-i-a-b-image">
									<img src={Settings} alt="Accessibility Settings" />
								</div>
								<div className="p-i-a-b-title">
									Accessibility settings
								</div>
							</button>
						</div>
					</div>
				</div>
				{/* Next Button */}
				<div className="next-button-container">
					<button onClick={this.handleChange}
						className="next-button">
						<img src={NextButton} alt="" />
					</button>
				</div>
			</div>
		)
	}
}

PlayerInfo.contextType = GameContext