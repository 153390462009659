import React, { Component } from 'react';
import propeller from "./assets/propellerWithBorder.svg"
import "./spinGame.css";
import { GameContext } from '../../context/GameContext';
import Results from '../results/results'
import { setLocalStorageItem } from '../../helpers/helpers';

export default class SpinGamePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			resultPopup: false,
			list: [
				"30",
				"130",
				"200",
				"10",
				"100",
				"60",
				"300",
				"250",
			],
			colours: [
				'rgb(241,72,143)',
				'rgb(237,119,221)',
				'rgb(120,235,246)',
				'rgb(3,193,215)',
				'rgb(55,216,91)',
				'rgb(255,226,72)',
				'rgb(255,150,66)',
				'rgb(248,91,69)'
			],
			score: 0,
			radius: 75, // PIXELS
			rotate: 0, // DEGREES
			easeOut: 0, // SECONDS
			result: null, // INDEX
			spinning: false,
			numOptions: 8, // equal to list length
			top: 7, // results from topPosition for 8 options
			offset: 0 // results from topPosition for 8 options
		}
	}

	onClose = e => {
		this.props.onClose && this.props.onClose(e)
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}

	toggleResultPopup() {
		if (this.state.resultPopup === false) {
			setLocalStorageItem('spinShow', false);
			this.setState({
				spinGamePopup: false,
				resultPopup: true
			})
		}
	}

	componentDidMount() {
		// generate canvas wheel on load
		if (this.props.show)
			this.renderWheel();
	}

	renderWheel() {
		let numOptions = this.state.numOptions;
		let arcSize = 2*Math.PI/numOptions;

		// dynamically generate sectors from state list
		let angle = 0;
		for (let i = 0; i < numOptions; i++) {
			this.renderSector(this.state.list[i], angle, arcSize, this.state.colours[i]);
			angle += arcSize;
		}
	}

	renderSector(text, start, arc, color) {
		// create canvas arc for each list element
		let canvas = document.getElementById("wheel");
		let ctx = canvas.getContext("2d");
		let x = canvas.width / 2;
		let y = canvas.height / 2;
		let radius = this.state.radius;
		let startAngle = start;
		let endAngle = start + arc;
		let baseSize = radius * 3.33;
		let textRadius = baseSize - 150;

		ctx.beginPath();
		ctx.arc(x, y, radius, startAngle, endAngle, false);
		ctx.lineWidth = radius * 2;
		ctx.strokeStyle = color;
		ctx.font = "17px Arial";
		ctx.fillStyle = "#000";
		ctx.stroke();
		ctx.save();
		ctx.translate(
			baseSize + Math.cos(endAngle - arc / 2) * textRadius,
			baseSize + Math.sin(endAngle - arc / 2) * textRadius
		);
		ctx.rotate(endAngle - arc/2 + Math.PI/2);
		let pointsText = '+'+text;
		ctx.fillText(pointsText, -ctx.measureText(pointsText).width/2, 0, ctx.rotate((-Math.PI/2)));
		ctx.restore();
	}


	spin = () => {
		// set random spin degree and ease out time
		// set state variables to initiate animation
		let randomSpin = Math.floor(Math.random() * 900) + 500;
		this.setState({
			rotate: randomSpin,
			easeOut: 2,
			spinning: true
		});

		// calcalute result after wheel stops spinning
		setTimeout(() => {
			this.getResult(randomSpin);
			this.toggleResultPopup();
		}, 3000);
	};

	getResult = spin => {
		// the +4 is because the first segment is drawn at 90 degrees clockwise
		// and the 8 is because it spins anticlockwise but draws segments clockwise
		let segment = (8 - Math.ceil(((spin / 45) + 2 )% 8))%8;
		if (segment < 0)
			segment = segment + 8;
		const { list } = this.state;
		let score = list[segment];

		// set state variable to display result
		this.setState({
			result: 1,
			score: score
		});
		this.context.updateScore(score)
	};


	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			// display: this.state.result == null ? 'block' : 'none'
			<div >
				 <div className="hint-popup-container">
					<div className="h-p-box-container" style={{ position:'relative', marginTop:'5%', zIndex:'100', width:'95%', height:'85%', maxHeight:'480px' }}>
						<div className="spinGameApp">
							<div className="spinTitle" style={{ position:'absolute', top:'1em' }}>ZONE COMPLETED!</div>
							<canvas
								id="wheel"
								width="500"
								height="500"
								border="2px solid black"
								style={{
									WebkitTransform: `rotate(${this.state.rotate}deg)`,
									WebkitTransition: `-webkit-transform ${this.state.easeOut}s ease-out`,
									position:'absolute',
									top:'-45px'
								}}
							/>
							<img src={propeller} id="selector" alt='propeller' width={this.state.radius * 4} style={{ position:'absolute', top:'205px' }}/>

							<div className="spinText" style={{ position:'absolute', top:'370px'}}>Spin the wheel and get extra points!</div>
							{
								!this.state.spinning && (
									<button type="button" id="spin" onClick={this.spin}>
										SPIN
									</button>
								)
							}
						</div>
					</div>
					<div style={{ zIndex:'101' }}>
						{
							this.state.result > 0 &&
							<Results
								score={this.state.score}
								withProgressBar={false}
							/>
						}
					</div>
				</div>
			</div>
		)
	}

	/* This is no longer used but code kept here for reference */
	topPosition = (num, angle) => {
		// set starting index and angle offset based on list length
		// works upto 9 options
		let topSpot = null;
		let degreesOff = null;
		if (num === 9) {
			topSpot = 7;
			degreesOff = Math.PI / 2 - angle * 2;
		} else if (num === 8) {
			topSpot = 6;
			degreesOff = 0;
		} else if (num <= 7 && num > 4) {
			topSpot = num - 1;
			degreesOff = Math.PI / 2 - angle;
		} else if (num === 4) {
			topSpot = num - 1;
			degreesOff = 0;
		} else if (num <= 3) {
			topSpot = num;
			degreesOff = Math.PI / 2;
		}

		this.setState({
			top: topSpot - 1,
			offset: degreesOff
		});
	};
}
SpinGamePopup.contextType = GameContext

