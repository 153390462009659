import React, { Component } from 'react';
import './leaderboard.css';
import { Link } from "react-router-dom";
import AlfieImg from '../../assets/alfie-flag.svg';
import AmeliaImg from '../../assets/amelia-flag.svg'
import CloseButton from './assets/16/icon.png';
import QuizButton from './assets/quiz-image.png';
import GoldTrophy from './assets/gold-trophy.png';
import SilverTrophy from './assets/silver-trophy.png';
import BronzeTrophy from './assets/bronze-trophy.png';
import StarImage from './assets/star-image.png';
import Axios from "axios";
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/helpers';

class Leaderboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isPopupActive: false,
			isRecapPopupActive: false,
			data: [],
		}
	}

	toggleRecapPopup = () => {
		this.setState({
			isPopupActive: !this.state.isPopupActive,
			isRecapPopupActive: !this.state.isRecapPopupActive
		})
	}

	handleCloseClick = () => {
		this.setState({
			isPopupActive: false,
			isRecapPopupActive: false,

		})
	}
	componentDidMount() {

		Axios.get(process.env.REACT_APP_SERVER_DOMAIN_MAIN + "data/get-leaderboard-scores", {
			params: {
				playerUuid: getLocalStorageItem("playerUuid")
			}
		})
		.then((response) => {
			this.setState({ data: response.data });
			console.log('response leaderboard', response.data);

		})
		.catch(err => console.log(err));
	}
	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	render() {
		let data = this.state.data;
		if (data.length === 0) return null;
		const isPopupActive = this.state.isPopupActive;
		const isRecapPopupActive = this.state.isRecapPopupActive;
		data.map((y) => (y.name === undefined || y.name === 'You') ? setLocalStorageItem("player-position", y.position) : '');

		const RecapPopup = () => {
			return (
				isPopupActive === true && (
					<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " recap-popup-container"}>

						<div className="rp-box-container">

							{/* Close Button */}
							<button className="rpb-button" onClick={this.handleCloseClick}>
								<img src={CloseButton} alt="Close Popup" />
							</button>

							{isRecapPopupActive === true && (
								<div className="rpb-inner-container">

									{/* Title */}
									<div className="rpb-section rpb-title">
										POINTS BOOST
									</div>

									{/* Image */}
									<div className="rpb-section rpb-image">
										<img src={QuizButton} alt="Quiz Button" />
									</div>
									{/* Description */}
									<div className="rpb-section rpb-description">
										Answer some quick fire questions from across your adventure for one last chance to boost your score.
									</div>

									{/* Subtitle */}
									<div className="rpb-section rpb-subtitle">
										ONLY ONE CHANCE AND NO HINTS!
									</div>

									{/* Button Container */}
									<div className="rpb-section rpb-button-container">
										<Link to="/recap" className="rpb-ok-button">
											OK
										</Link>
									</div>
								</div>
							)}
						</div>
					</div>
				)
			)
		}

		return (
			<div className="container leaderboard-container">
				<RecapPopup />
				<div className="leaderboard-inner-container">

					{/* Character Image */}
					<div className="l-image-container">
						{((getLocalStorageItem("character") === 'amelia') && (<img src={AmeliaImg} alt="Amelia" />)) ||
							((getLocalStorageItem("character") === 'alfie') && (<img src={AlfieImg} alt="Alfie" />)) ||
							((getLocalStorageItem("character") === undefined) && (<img src={AlfieImg} alt="Alfie" />))}
					</div>

					{/* Points Total */}
					<div className="l-points-container">
						{getLocalStorageItem("score")} points!
					</div>

					{/* Leaderboard Table Section */}
					<div className="l-table-container">
						<div className="lt-inner-container">
							<table className="l-table">
								{data.map((y) => {
									if (y.name === undefined || y.name === 'You')
										setLocalStorageItem('playerPosition', y.position);
									return (
										<tr key={y.position} className="l-table-row">
											<td className="ltr-position">
												<div className="ltrp-inner-container">
													<div className="ltrp-image">
														{y.position === 1 ?
															<img src={GoldTrophy} alt="first Place" />
															: y.position === 2 ?
																<img src={SilverTrophy} alt="second Place" />
																: y.position === 3 ?
																	<img src={BronzeTrophy} alt="Third Place" />
																	: (y.name === undefined || y.name === 'You') ?
																		<img src={StarImage} alt="Your Position" />
																		: <img  alt='' />
														}
													</div>
													<div className="ltrp-text" style={{ fontWeight: (y.name === undefined || y.name === 'You') ? 'bold' : 'normal' }}>{y.position}</div>
												</div>
											</td>
											<td className="ltr-username" style={{ fontWeight: (y.name === undefined || y.name === 'You') ? 'bold' : 'normal' }}>
												{(y.name === undefined || y.name === 'You') ? 'You' : y.name}</td>
											<td className="ltr-score" style={{ fontWeight: (y.name === undefined || y.name === 'You') ? 'bold' : 'normal' }}>{y.score}p</td>
										</tr>
									)
								})}
							</table>
						</div>
					</div>

					{/* Leaderboard Table Section */}

					{/* Button Section */}



                        {
                            getLocalStorageItem("recapDone") === true &&
                            <div className="l-button-container">
                                <div className="lb-button lb-finish-recap-button" >
                                    <Link to='/certificate' style={{ 'color': 'white' }}>
                                        FINISH
                                    </Link>
                                </div>
                                <div className = "lb-button lb-play-button">
									<Link to='/' style={{ 'color': 'white' }}>
									   PLAY AGAIN
									</Link>
                                </div>
                            </div>
                        }
                        {  getLocalStorageItem("recapDone") === false &&
                            <div className="l-button-container">
                                {/* Recap Button */}
                                <div
                                    className = "lb-button lb-recap-button"
                                    onClick = {this.toggleRecapPopup} >
                                    RECAP - get more points
                                </div>
                                {/* Finish Button */}
                                <div className="lb-button lb-finish-button" >
                                    <Link to='/certificate' style={{ 'color': 'white' }}>
                                        FINISH
                                    </Link>
                                </div>
                            </div>
                        }
				</div>
			</div>
		);
	}
}

export default Leaderboard;
