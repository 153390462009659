import BackImg from "../../images/left-corner-close.png";
import React from "react";
import './back.css';
import { useHistory } from 'react-router-dom';

// Back button works by accessing history via React Router
const BackExit = () => {

    let history = useHistory(); // useHistory component from react-router-dom

    return(
        <div className="back-btn back-exit-btn"> {/* Back Button Container */}
            <button
                onClick={() => history.goBack()}
            >
                <img src={BackImg} alt="" />
            </button>
        </div> // End of Back Button Container
    )
}

export default BackExit;

