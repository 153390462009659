import { Link } from 'react-router-dom';
import React from "react";
import './exitGame.css';
import exitIcon from './assets/exitIcon.svg'
import { Component } from 'react';
import { getLocalStorageItem } from '../../helpers/helpers';
import { GameContext } from '../../context/GameContext';

export default class ExitGame extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exitPopup: false,
		}
	}

	toggleExitPopup() {
		this.setState({
			exitPopup: !this.state.exitPopup,
		})
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	render() {
		const ExitPopup = () => {
			return (

				<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " hint-popup-container"}>
					<div className="h-p-box-container">
						<div className="hpb-inner-container ">
							<div className="hpb-close-button">
							</div>
							{/* Body Section */}
							<div className="hpb-body-container">

								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									DO YOU WANT TO END ADVENTURE NOW?
								</div>

								{/* Description */}
								{
									<div className="hpdb-inner-container hpdb-description-container exit-description" >
										If you leave now, you will still receive your final score and the certificate,
										but won't be able to restart the adventure with the current score
									</div>
								}
								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container">
									<button className="hpdb-button" style={{ width: '80%' }} onClick={() => this.toggleExitPopup()}>
										NO - I'll keep going
									</button>
									<button className="hpdb-button end-btn">
										<Link to={"/leaderboard"} style={{ color: 'white' }}>
											YES - End adventure
										</Link>
									</button>
								</div>

							</div>

						</div>
					</div>
				</div>

			)
		}
		const isExitPopupShown = this.state.exitPopup;
		return (
			<div >
				{
					isExitPopupShown === true &&
					<ExitPopup />
				}
				<button className="exit-btn" onClick={() => this.toggleExitPopup()} >
					<img src={exitIcon} alt="exit" />
				</button>
			</div>
		)
	}
}
ExitGame.contextType = GameContext

// export default ExitGame;

