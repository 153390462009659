import React, { Component } from 'react';
import './splash.css';
import AbLogo from './assets/ab-logo.svg';
import Alfie from './assets/alfie-right.svg';
import Amelia from './assets/amelia-left.svg';

class Splash extends Component {
	componentDidMount() {
		setTimeout(() => {
			this.props.history.push('/welcome')
		}, 3000)
	}
	render() {
		return (
			<div className="container ab-container">
				<div className="ab-inner-container">
					{/* logo */}
					<div className="ab-image-container">
						<img src={AbLogo} alt="" />
					</div>
					{/* char */}
					<div className="char-container">
						<div className="ab-char-container ab-char-margin-l">
							<img src={Amelia} alt="amelia" width='230' />
						</div>
						<div className="ab-char-container">
							<img src={Alfie} alt="alfie" width='230' />
						</div>
					</div>

				</div>
			</div>
		);
	}
}
export default Splash;