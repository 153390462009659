import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from "axios";
import "./welcome.css";
import game from '../global-var';

import PlaneVector from './assets/plane-vector.svg';

class Welcome extends Component {
    
    render () {
		localStorage.clear()
        //map
        Axios({
        method: 'post',
        url: process.env.REACT_APP_SERVER_DOMAIN_MAIN+'data/get-map',
        }).then((response) => {
            game.map = response.data;
            console.log(game.map);
        });
        game.fontSize= 'medium';
        game.sound= 'off';
        return (
            <div className="container welcome-container">
                <div className="welcome-inner-container">
                    {/* Plane Image Section */}
                    <div className="welcome-image-container">
                        <img src={PlaneVector} alt=""/>
                    </div>
                    {/* Information Section */}
                    <div className="welcome-info-container">
                        {/* Title Section */}
                        <div className="w-i-title-container">
                            Aerospace Adventure
                        </div>
                        {/* Description Section */}
                        <div className="w-i-description-container">
                            Join Alfie Fox and his sister Amelia for fun quizzes and games.<br/>Discover aerospace facts and compete for the highest score.
                        </div>
                    </div>
                    {/* Button Section */}
                    <div className="welcome-button-container">
                        <Link to="/player-info">
                                Take me on board!
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default Welcome;