import React, { createContext, Component } from 'react';
import Axios from "axios";
import {setLocalStorageItem, getLocalStorageItem} from '../helpers/helpers'

export const GameContext = createContext()

/**
 * Class GameProvider
 *
 * GameProvider provides global accessible state and functionality
 * to all files that are wrapped within the <GameProvider></GameProvider> tags
 * such as in App.js around route areas.
 *
 * The Class manages the state of the game from buildings zones and tokens.
 *
 */
class GameProvider extends Component {

		state = {
			player:					(localStorage.getItem('player') 				? getLocalStorageItem('player') 				: ''),
			playerUuid:				(localStorage.getItem('playerUuid') 			? getLocalStorageItem('playerUuid') 			: ''),
			buildings:				(localStorage.getItem('buildings')				? getLocalStorageItem('buildings') 			: ''),
			map: 					(localStorage.getItem('map') 					? getLocalStorageItem('map') 					: ''),
			concordeEras:			(localStorage.getItem('concordeEras') 			? getLocalStorageItem('concordeEras') 			: ''),
			aeroEraKeys: 			(localStorage.getItem('aeroEraKeys') 			? getLocalStorageItem('aeroEraKeys') 			: ''),
			concordeEraKeys:		(localStorage.getItem('concordeEraKeys') 		? getLocalStorageItem('concordeEraKeys') 		: ''),
			currentIndex: 			(localStorage.getItem('currentIndex') 			? getLocalStorageItem('currentIndex') 			: ''),
			currentBuilding:		(localStorage.getItem('currentBuilding') 		? getLocalStorageItem('currentBuilding') 		: ''),
			aeroEras: 				(localStorage.getItem('aeroEras') 				? getLocalStorageItem('aeroEras') 				: ''),
			startingPoint:			(localStorage.getItem('startingPoint') 			? getLocalStorageItem('startingPoint') 		: ''),
			currentEra: 			(localStorage.getItem('currentEra') 			? getLocalStorageItem('currentEra') 			: ''),
			currentEraTokens:		(localStorage.getItem('currentEraTokes') 		? getLocalStorageItem('currentEraTokens') 		: ''),
			allTokenList:			(localStorage.getItem('allTokenList') 			? getLocalStorageItem('allTokenList') 			: ''),
			spinWheelShown:			(localStorage.getItem('spinWheelShown') 		? getLocalStorageItem('spinWheelShown') 		: ''),
			currentEraX:			(localStorage.getItem('currentEraX') 			? getLocalStorageItem('currentEraX') 			: ''),
			currentEraY:			(localStorage.getItem('currentEraY') 			? getLocalStorageItem('currentEraY') 			: ''),
			score:					(localStorage.getItem('score') 					? getLocalStorageItem('score') 				: 0),
			character:				(localStorage.getItem('character') 				? getLocalStorageItem('character') 			: 'alfie'),
			eraIndex:				(localStorage.getItem('eraIndex') 				? getLocalStorageItem('eraIndex') 				: 0),
			concordeIndex:			(localStorage.getItem('concordeIndex') 			? getLocalStorageItem('concordeIndex') 		: 0),
			completedZones:			(localStorage.getItem('completedZones')			? getLocalStorageItem('completedZones') 		: []),
			tokensTotal:			(localStorage.getItem('tokensTotal') 			? getLocalStorageItem('tokensTotal') 			: 0),
			tokensCompleted:		(localStorage.getItem('tokensCompleted') 		? getLocalStorageItem('tokensCompleted') 		: 0),
			spinShow:				(localStorage.getItem('spinShow') 				? getLocalStorageItem('spinShow') 				: false),
			spinEraShown:			(localStorage.getItem('spinEraShown') 			? getLocalStorageItem('spinEraShown') 			: []),
			error:					(localStorage.getItem('error') 					? getLocalStorageItem('error') 				: ''),
			fontSize:				(localStorage.getItem('fontSize')				? getLocalStorageItem('fontSize') 				: 'medium'),
			showAccessibility:		(localStorage.getItem('showAccessibility')		? getLocalStorageItem('showAccessibility') 	: false),
			galleries_completed: 	(localStorage.getItem('galleries_completed')	? getLocalStorageItem('galleries_completed') 	: false),
			concorde_completed: 	(localStorage.getItem('showAccessibility')		? getLocalStorageItem('showAccessibility') 	: false),
			recapDone:				(localStorage.getItem('recapDone')				? getLocalStorageItem('recapDone')				: false),
			playerPosition:			(localStorage.getItem('playerPosition')			? getLocalStorageItem('playerPosition')		: 0),
		}


	fetching_data = false


	/**
	 * Api call to server to get map and game data
	 * Results are stored in the state variable
	 */
	fetchDataFromServer()
	{
		this.fetching_data = true

		try {
			Axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER_DOMAIN_MAIN + 'data/get-map',
			}).then (  (response) => {
				this.setState({map: response.data})
				this.setState({tokensTotal: this.state.map.tokenCount})
				this.setState({currentIndex: 0})
				this.setState({buildings: response.data.buildings})
				this.setState({aeroEras: response.data.buildings['aerospace-galleries'].eras})
				this.setState({concordeEras: response.data.buildings['concorde-hangar'].eras})
				this.setState({aeroEraKeys: Object.keys(this.state.aeroEras)})
				this.setState({concordeEraKeys: Object.keys(this.state.concordeEras)})
				this.setState({allTokenList: this.setTokenStates()})

				this.storeDataToLocalStorage()

			});

		} catch (e) {
			this.fetching_data = false
			this.setState({error: e})
			window.location.href = '/oops-error'
		}
	}


	/**
	 *  Obtain all tokens available for current area
	 */
	setTokenStates()
	{
		const nestedObject = []
		for (const eraKey in this.state.eraKeys) {
			const numberCorrection = Number(eraKey) + 1
			const era = "era-" + numberCorrection
			nestedObject.push(this.state.eras[era].tokens)
		}

		return nestedObject
	}

	setCharacter = (character) =>
	{
		this.setState({character: character})
		setLocalStorageItem('character', character)
	}

	setPlayer = (name) =>
	{
		if (name.length > 0) {
			const params = new URLSearchParams();
			params.append('name', name);
			Axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER_DOMAIN_MAIN+'api/add-player',
				data: params
			}).then((response) => {
				console.log(response.data);
				this.setState({player: name})
				setLocalStorageItem('player', name)
				this.setState({playerUuid: response.data.playerUuid})
				setLocalStorageItem('playerUuid', response.data.playerUuid)
			});
		}
	}

	completeToken = (token_id) =>
	{
		let eras
		let eraKeys
		let currentEra
		let completedTokens = getLocalStorageItem("tokensCompleted");
		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries'){
			eras = getLocalStorageItem('aeroEras')
			eraKeys = getLocalStorageItem('aeroEraKeys')
			currentEra = eraKeys[getLocalStorageItem('eraIndex')];
		} else
		{
			eras = getLocalStorageItem('concordeEras')
			eraKeys = getLocalStorageItem('concordeEraKeys')
			currentEra = eraKeys[getLocalStorageItem('concordeIndex')];
		}

		eras[currentEra].tokens[token_id].complete = true
		setLocalStorageItem("tokensCompleted", completedTokens+1);

		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries'){
			setLocalStorageItem("aeroEras", eras)
		} else {
			setLocalStorageItem("concordeEras", eras)
		}

	}


	setBuilding = (building=getLocalStorageItem("currentBuilding"), start="default") =>
	{
		this.setState({currentBuilding: building})
		setLocalStorageItem('currentBuilding', building)

		this.setState({startingPoint: building})
		setLocalStorageItem('startingPoint', building)

		if (building === 'aerospace-galleries')
		{
			start = start==="default" ? 'era-1' : start
			this.setState({currentEraY: this.state.aeroEras[start].centre_y})
			setLocalStorageItem('currentEraY', this.state.aeroEras[start].centre_y)
			this.setState({currentEraX: this.state.aeroEras[start].centre_x})
			setLocalStorageItem('currentEraX', this.state.aeroEras[start].centre_x)

		} else {
			start = start==="default" ? 'concorde-1' : start
			this.setState({currentEraY: this.state.concordeEras[start].centre_y})
			setLocalStorageItem('currentEraY', this.state.concordeEras[start].centre_y)

			this.setState({currentEraX: this.state.concordeEras[start].centre_x})
			setLocalStorageItem('currentEraX', this.state.concordeEras[start].centre_x)

		}
		this.setState({currentEra: start})
		setLocalStorageItem('currentEra', start)

	}


	/**
	 * Gets the error stored in state
	 * @returns string
	 */
	getError()
	{
		return this.state.error;
	}



	/**
	 * Utilise local storage to copy state to so refreshing the browser will
	 * not cause the game to crash. Function loops through states key value pairs
	 * and store the value as a JSON string
	 */
	storeDataToLocalStorage()
	{
		for (const [key, value] of Object.entries(this.state)) {
			setLocalStorageItem(key, value);
		}
	}

	/**
	 * Update current store in state with += points gained
	 *
	 * @param {string} value number of additional points gaines
	 */
	updateScore = (value) =>
	{
		if(isNaN(value)){
			value = 0;
		}
		console.log("Adding", value)
		let newValue = Number(getLocalStorageItem('score')) + Number(value)
		setLocalStorageItem("score", newValue)

		const params = new URLSearchParams();
        params.append('uuid', getLocalStorageItem('playerUuid'));
        params.append('score', value);

		Axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER_DOMAIN_MAIN+'data/save-new-score',
            data: params
            }).then((response) => {
				// do nothing
            })
             .catch(err => console.log(err))
	}

	/**
	 * Returns the current score kept in state
	 *
	 * @returns {string} current scout
	 */
	currentScore = () => {
		return this.state.score;
	}


	updateState()
	{
		for (const [key, value] of Object.entries(this.state)) {
			let newValue = localStorage.getItem(key)

			if (newValue !== value){
				this.setState({[key]: newValue })
			}
		}
	}

	isBuildingComplete = () => {
		let eraKeys
		let eraCompletedKeys = getLocalStorageItem('completedZones')
		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries'){
			eraKeys = getLocalStorageItem('aeroEraKeys')
			let res = eraKeys.filter(f => !eraCompletedKeys.includes(f))
			if(res.length === 0)
			{
				setLocalStorageItem('galleries_completed', true)
			}
		} else
		{
			eraKeys = getLocalStorageItem('concordeEraKeys')
			let res = eraKeys.filter(f => !eraCompletedKeys.includes(f))
			if(res.length === 0)
			{
				setLocalStorageItem('concorde_completed', true)
			}

		}
	}


	checkZoneComplete = () =>
	{
		if(localStorage.getItem("currentBuilding") !== null){
			let isCompleted = true

			let eras
			let eraKeys
			let currentEra
			if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries'){
				eras = getLocalStorageItem('aeroEras')
				eraKeys = getLocalStorageItem('aeroEraKeys')
				currentEra = eraKeys[getLocalStorageItem('eraIndex')];
			} else
			{
				eras = getLocalStorageItem('concordeEras')
				eraKeys = getLocalStorageItem('concordeEraKeys')
				currentEra = eraKeys[getLocalStorageItem('concordeIndex')];
			}
			for (const token in eras[currentEra].tokens)
			{
				if (eras[currentEra].tokens[token].complete === false)
				{
					isCompleted = false
					return isCompleted
				}
			}
			if (isCompleted){
				let shownEras = getLocalStorageItem("spinEraShown")
				let completedZones = getLocalStorageItem('completedZones')
				if (completedZones.indexOf(currentEra) === -1){
					completedZones.push(currentEra)
					setLocalStorageItem('completedZones', completedZones)

				}
				if (shownEras.indexOf(currentEra) === -1){
					shownEras.push(currentEra)
					setLocalStorageItem('spinEraShown', shownEras)
					setLocalStorageItem('spinShow', true)
					this.nextLocation()
				}

			}
		}

	}

	spinShow = () => {
		let completedZones = getLocalStorageItem("completedZones")
		let spinBeenShown = getLocalStorageItem("spinEras")

		let res = completedZones.filter(f => !spinBeenShown.includes(f))
		setLocalStorageItem("spinEras", res)
		console.log("should show spin!", res)
	}

	spinShown = () => {
		let shownEras = getLocalStorageItem("spinEraShown")
		if (shownEras.indexOf(getLocalStorageItem("currentEra") === -1)){
			shownEras.push(getLocalStorageItem("currentEra"))
		}
		setLocalStorageItem("spinEraShown", shownEras)
		setLocalStorageItem('showSpin', false)
	}

	nextLocation = () =>
	{

		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries'){
			let eraKeys = getLocalStorageItem('aeroEraKeys')
			let offset = Number(getLocalStorageItem("eraIndex")) + 1
			let completedZones = getLocalStorageItem('completedZones')
			let result = null

			for(let i = 0; i < eraKeys.length; i++) {
				let pointer = ( i + offset) % eraKeys.length;
				if (completedZones.indexOf(eraKeys[pointer]) === -1)
				{
					result = eraKeys[pointer]
					break;
				}
				else {
					result = 'era-1'
				}
			}

			this.changeLocation(result)
		}
	}

	changeLocation(zoneKey)
	{
		const current = getLocalStorageItem("currentBuilding")
		if(zoneKey === "concorde-1" && current !== "concorde-hangar")
		{
			setLocalStorageItem("currentBuilding", "concorde-hangar")
			setLocalStorageItem("currentEra", zoneKey)
			const areas = getLocalStorageItem("concordeEras")
			setLocalStorageItem('currentEraY', areas[zoneKey].centre_y)
			setLocalStorageItem('currentEraX', areas[zoneKey].centre_x)
			const EraKeys = Object.keys(areas)
			const index = EraKeys.indexOf(zoneKey)
			setLocalStorageItem("concordeIndex", index)
		}
		if (zoneKey !== "concorde-1" && current === "concorde-hangar")
		{
			setLocalStorageItem("currentBuilding", "aerospace-galleries")
			setLocalStorageItem("currentEra", zoneKey)
			const areas = getLocalStorageItem("aeroEras")
			setLocalStorageItem('currentEraY', areas[zoneKey].centre_y)
			setLocalStorageItem('currentEraX', areas[zoneKey].centre_x)
			const EraKeys = Object.keys(areas)
			const index = EraKeys.indexOf(zoneKey)
			setLocalStorageItem("eraIndex", index)
		}
		if (zoneKey !== "concorde-1" && current !== "concorde-hangar")
		{
			setLocalStorageItem("currentEra", zoneKey)
			const areas = getLocalStorageItem("aeroEras")
			setLocalStorageItem('currentEraY', areas[zoneKey].centre_y)
			setLocalStorageItem('currentEraX', areas[zoneKey].centre_x)
			const EraKeys = Object.keys(areas)
			const index = EraKeys.indexOf(zoneKey)
			setLocalStorageItem("eraIndex", index)
		}

	}

	render() {

		if (!this.fetching_data && localStorage.getItem('map') === null)
		{
			this.fetchDataFromServer();
		}
		if(localStorage.getItem('map') !== null && this.state.map === null){
			console.log('Updating state with localStorage')
			for (const [key] of Object.entries(this.state)){
				const local_value = JSON.parse(localStorage.getItem(key))
				this.setState({key, local_value})
			}
		}

		const {
			getError,
			updateScore,
			completeToken,
			setCharacter,
			setBuilding,
			setPlayer,
			changeLocation,
			checkZoneComplete,
			spinShow,
			spinShown,
			isBuildingComplete
		} = this;

		return (
			<GameContext.Provider value={
				{
					...this.state,
					getError,
					updateScore,
					completeToken,
					setCharacter,
					setBuilding,
					setPlayer,
					changeLocation,
					checkZoneComplete,
					spinShow,
					spinShown,
					isBuildingComplete

				}
			}>
				{this.props.children}
			</GameContext.Provider>
		)
	}
}
export default GameProvider;