import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './startingPoint.css';
import game from '../global-var';
import Back from "../../components/backButton/back";
import NextButton from "../../images/next-button.svg";
import AerospaceUnselected from './assets/aerospace-unselected.svg'; import SelectedAerospace from './assets/aerospace-selected.svg'
import ConcordeUnselected from './assets/concorde-unselected.svg'; import SelectedConcorde from './assets/concorde-selected.svg';
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/helpers';
import { GameContext } from '../../context/GameContext'

class StartingPoint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEmpty: false,
			AerospaceSelected: (game.startingPoint === 'aerospace-galleries') ? true : false,
			ConcordeSelected: (game.startingPoint === 'concorde-hangar') ? true : false
		};
	}

	selectAerospace = () => {
		this.context.setBuilding('aerospace-galleries');
		this.setState({
			AerospaceSelected: true,
			ConcordeSelected: false
		});
		game.startingPoint = 'aerospace-galleries';
		game.currentBuilding = 'aerospace-galleries';
		setLocalStorageItem('startingPoint', 'aerospace-galleries')
		setLocalStorageItem('currentBuilding', 'aerospace-galleries')
		console.log(game);
	}

	selectConcorde = () => {
		this.context.setBuilding('concorde-hangar');

		this.setState({
			AerospaceSelected: false,
			ConcordeSelected: true
		});
		game.startingPoint = 'concorde-hangar';
		game.currentBuilding = 'concorde-hangar';
		setLocalStorageItem('startingPoint', 'concorde-hangar')
		setLocalStorageItem('currentBuilding', 'concorde-hangar')
		console.log(game);
	}
	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	handleChange = (event) => {
		if (game.startingPoint == null) {
			this.setState({
				isEmpty: !this.state.isEmpty,
			})
		} else {
			this.props.history.push('/ready');
		}

	}
	render() {
		const { AerospaceSelected } = this.state;
		const { ConcordeSelected } = this.state;
		const isEmptyStPoint = this.state.isEmpty;
		const EmptyStPoint = () => {
			return (
				<div className="hint-popup-container">
					<div className="popup-container">
						<div className="popup-inner-container">
							<div className="popup-content">
								DON'T FORGET TO PICK YOUR STARTING POINT
							</div>
							<button
								onClick={this.handleChange}
								className="basic-btn a-save-btn"
							>
								<h3>OK</h3>
							</button>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " container sp-page-container"}> {/* Page Container */}
				{
					isEmptyStPoint === true &&
					<EmptyStPoint />
				}
				{/* Back Button */}
				<div className="back-button">
					<Back className="back-button" />
				</div>
				<div className="c-p-inner-container">
					{/* Title Section */}
					<div className="c-p-title-container">
						Pick Starting Point
					</div>
					{/* sp Section */}
					<div className="c-p-sp-container">

						{/* Aerospace */}
						<Link to="/ready" className="c-p-c-sp" onClick={this.selectAerospace}>
							{AerospaceSelected === true &&
								<div className="cpcc-sp-image">
									<img src={SelectedAerospace} alt="Aerospace is Selected!" className="cpcc-selected" style={{ opacity: '100%' }} />
									<img src={AerospaceUnselected} alt="Select Aerospace!" className="cpcc-unselected" style={{ opacity: '0%' }} />
								</div>
							}
							{AerospaceSelected === false &&
								<div className="cpcc-sp-image">
									<img src={SelectedAerospace} alt="Aerospace is Selected!" className="cpcc-selected" style={{ opacity: '0%' }} />
									<img src={AerospaceUnselected} alt='Select Aerospace!' className="cpcc-unselected" style={{ opacity: '100%' }} />
								</div>
							}
						</Link>
						<div className="starting-point-desc">
							<h3>THE AEROSPACE GALLERIES</h3>
							<p>Take part in the journey through 100<br />years of aviation history</p>
						</div>
						<br/>
						{/* Concorde */}
						<Link to="/ready" className="c-p-c-sp" onClick={this.selectConcorde}>
							{ConcordeSelected === true &&
								<div className="cpcc-sp-image">
									<img src={SelectedConcorde} alt="Concorde is Selected!" className="cpcc-selected" style={{ opacity: '100%' }} />
									<img src={ConcordeUnselected} alt="Select Concorde!" className="cpcc-unselected" style={{ opacity: '0%' }} />
								</div>
							}
							{ConcordeSelected === false &&
								<div className="cpcc-sp-image">
									<img src={SelectedConcorde} alt="Concorde is Selected!" className="cpcc-selected" style={{ opacity: '0%' }} />
									<img src={ConcordeUnselected} alt='Select Concorde!' className="cpcc-unselected" style={{ opacity: '100%' }} />
								</div>
							}
						</Link>
						<div className="starting-point-desc">
							<h3>THE CONCORDE HANGAR</h3>
							<p>Step aboard the last Concorde</p>
						</div>
					</div>
				</div>
				{/* End of Inner Container */}
				{/* Next Button */}
				<div className="next-button-container">
					<button onClick={this.handleChange} className="next-button">
						<img src={NextButton} alt="" />
					</button>
				</div>
			</div>
		);
	}
}
StartingPoint.contextType = GameContext
export default StartingPoint;