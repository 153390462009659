
function getLocalStorageItem (key)
{
		return JSON.parse(localStorage.getItem(key))
}

function setLocalStorageItem(key, value) {
	localStorage.setItem(key, JSON.stringify(value))
}

export {getLocalStorageItem, setLocalStorageItem}

