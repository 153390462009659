import React, { Component } from "react";
import { Link } from 'react-router-dom';
import alfie from '../../assets/alfie-avatar.svg';
import amelia from '../../assets/amelia-avatar.svg';
import HeaderBackdrop from '../../assets/header-background.svg';
import help from '../../assets/help.svg'
import Accessibility from '../accessibility/accessibility'
import { getLocalStorageItem } from "../../helpers/helpers";

export default class Header extends Component {
	state = {
		show: false,
	};
	showModal = e => {
		this.setState({ show: !this.state.show })
	}

	render() {
		return (
			<div className="w-m-header-container">
				<Accessibility onClose={this.showModal} show={this.state.show} />
				<div className="w-m-h-image">
					<img src={HeaderBackdrop} alt="Header Section" />
				</div>
				<div className="w-m-h-inner-container">
					<button className="w-m-h-character">
						{((getLocalStorageItem("character") === 'amelia') && (<img src={amelia} alt="User" />)) ||
							((getLocalStorageItem("character") === 'alfie') && (<img src={alfie} alt="User" />)) ||
							((getLocalStorageItem("character") === undefined) && (<img src={alfie} alt="User" />))}
					</button>
					<div className="w-m-h-score-container">
						<div className="wmhs-title">
							{getLocalStorageItem("score")} points
						</div>
						<progress style={{ height: '10px' }} value={getLocalStorageItem("tokensCompleted")} max={getLocalStorageItem("tokensTotal")} />
					</div>
					<div className="w-m-h-settings">
						<Link to="/user-guide">
							<img  src={help} alt="help guide" />
						</Link>
					</div>

				</div>
			</div>
		)
	}
}

