import React, {Component} from "react"
import { Link } from 'react-router-dom'
import {getLocalStorageItem} from '../../helpers/helpers'
//import amelia from "../../assets/reward-avatar-amelia.svg";
import amelia from "../../assets/amelia-avatar.svg";
import alfie from "../../assets/alfie-avatar.svg";
import confetti from "../../assets/confetti.svg";

export default class Results extends Component {

    constructor (props) {
        super(props);
		this.state = {
			character: 'alfie'
		}
    }

    render() {
		//Store Character Image in variable to use in return
		const character = getLocalStorageItem("character") === 'amelia' ? (
			<img src={amelia} alt="User" />
		) : (
			<img src={alfie} alt="User" />
		)

		// check props to see if progress bar should be displayed
		const progressBar = Boolean(this.props.withProgressBar) ? (
			<div className="w-m-h-score-container" style={{width: '100%',paddingBottom:'50px'}}>
                <progress style={{width: '100%',height: '20px'}} value={getLocalStorageItem("tokensCompleted")} max={getLocalStorageItem("tokensTotal")} />
            </div>
		) : (
			<div></div>
		);

        return (
            <div className="confetti-screen">
                <div className="confetti-container confetti-animation">
                    <img src={confetti} alt="confetti" />
                </div>
                <div className="results-absolute">
                    <div className="results-container">
                        <div className="results-cont results-img" >
							{character}
                        </div>

                        <div className="results-cont results-text">
                            <h3> + {this.props.score}  points!</h3>
                            <h4>Congratulations!</h4>
                            {progressBar}
                        </div>
                        <div className="results-cont results-link">
                            <Link
                                to="/game-menu"
                                className="quiz-return-btn"
                            >
                                <h4>OK</h4>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
