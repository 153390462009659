
import React, { useState } from "react";
import LockedToken from "../../screens/pop-up/lockedToken";


const LockedTokenItem = (props) => {
	const [show, setShow] = useState(false)
	const image = props.image
	const markerSize = props.markerSize
	const mapHeight = props.mapHeight
	const mapScale = props.mapScale
	const mapWidth = props.mapWidth
	const token = props.token
	return (
		<div className="lockToken">
			<button style={{zIndex: 99}} >
				<img src={image} id="marker" alt=''
					style={{
						position: "absolute",
						width: markerSize + "px",
						top: ((mapHeight * mapScale) / 100) * token.position_y - (markerSize / 2),
						left: ((mapWidth * mapScale) / 100) * token.position_x - (markerSize / 2)
					}} onClick={() => setShow(true)} />
				<LockedToken show={show} onClose={() => setShow(false)} />
			</button>
		</div>
	)

}

export default LockedTokenItem