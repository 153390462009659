import React, { Component } from 'react';
import Back from "../../components/backButton/back";
import { Link } from "react-router-dom";
import './gameGuide.css';

import LockedIcon from '../../components/map/assets/adventure-locked.svg'
import IncompleteIcon from '../../components/map/assets/adventure-unlocked-quiz.svg'
import CompleteIcon from '../../components/map/assets/adventure-completed.svg'
import LocationPin from '../../components/locationButton/assets/locationPin.svg';
import ExitPin from '../../components/exitButton/assets/exitIcon.svg'


class GameGuide extends Component {


	render() {


		return (
			<div>
				<div className="container guide-container">
					<Back />
					<div className="help-inner-container">

						<div className="inner-guide-container" style={{ textAlign: 'center', overflow: 'scroll' }}>
							<h2>How To Play</h2>
							<p>Walk around, complete activities, collect points</p>

							<div className="guide-interaction-container">
								<img src={IncompleteIcon} alt="Incomplete Icon" width='78px'/>
								<div>
									<h4><strong>UNLOCKED</strong></h4>
									<p>Tap to start this activity and collect your points</p>
								</div>
							</div>

							<div className="guide-interaction-container">
								<img src={CompleteIcon} alt="Complete Icon"  width='78px'/>
								<div>
									<h4><strong>COMPLETED</strong></h4>
									<p>Activities you've already done</p>
								</div>
							</div>

							<div className="guide-interaction-container">
								<img src={LockedIcon} alt="Locked Icon"  width='78px'/>
								<div>
									<h4><strong>LOCKED</strong></h4>
									<p>Complete previous activities to unlock this one</p>
								</div>
							</div>

							<div className="guide-interaction-container">
								<div class="help-icon navigation">
									<img src={LocationPin} alt="Complete Icon" width="50%" />
								</div>
								<div>
									<h4><strong>NAVIGATION</strong></h4>
									<p>Change which zone you are in on the map</p>
								</div>
							</div>
							<div className="guide-interaction-container">
								<div class="help-icon exit">
									<img src={ExitPin} alt="Complete Icon" width="80%" />
								</div>
								<div>
									<h4><strong>EXIT</strong></h4>
									<p>If you need to leave, finish the adventure early</p>
								</div>
							</div>

						</div>
					</div>

				</div>


			</div>
		);
	}
}

export default GameGuide;
