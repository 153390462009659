import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './character.css';
import game from '../global-var';

import Back from "../../components/backButton/back";
import NextButton from "../../images/next-button.svg";

import AlfieUnselected from './assets/alfie-unselected.svg'; import SelectedAlfie from './assets/alfie-selected.svg'
import AmeliaUnselected from './assets/amelia-unselected.svg'; import SelectedAmelia from './assets/amelia-selected.svg';
import {GameContext} from '../../context/GameContext';
import { getLocalStorageItem } from '../../helpers/helpers'

class Character extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmpty: false,
            AlfieSelected: (game.character === 'alfie') ? true :false,
            AmeliaSelected: (game.character === 'amelia') ? true :false
        };
    }

    selectAlfie = () => {
		this.context.setCharacter('alfie')
        this.setState({
            AlfieSelected: true,
            AmeliaSelected: false
        });
        game.character = 'alfie';
        console.log(game);
    }

    selectAmelia = () => {
		this.context.setCharacter('amelia')
        this.setState({
            AlfieSelected: false,
            AmeliaSelected: true
        });
        game.character = 'amelia';
        console.log(game);
    }
    renderSwitch(param) {
        switch (param) {
            case "small": return 'class-small';
            case "large": return 'class-large';
            default: return 'class-medium';
        }
    }
    handleChange = (event) => {
        if (game.character == null) {
            this.setState({
                isEmpty: !this.state.isEmpty,
            })
        } else {
            this.props.history.push('/starting-point');
        }

    }
    render () {
		console.log('Character Screen')
        const { AlfieSelected } = this.state;
        const { AmeliaSelected } = this.state;
        const isEmptycharacter = this.state.isEmpty;
        const EmptyCharacter = () => {
            return (
                <div className="hint-popup-container">
                    <div className="popup-container">
                        <div className="popup-inner-container">
                            <div className="popup-content">
                                DON'T FORGET TO CHOOSE YOUR CHARACTER
                            </div>
                            <button
                                onClick={this.handleChange}
                                className="basic-btn a-save-btn"
                            >
                                <h3>OK</h3>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " container char-page-container"}> {/* Page Container */}
                {
                    isEmptycharacter === true &&
                    <EmptyCharacter />
                }
                {/* Back Button */}
                <div className="back-button">
                    <Back className="back-button"/>
                </div>
                <div className="c-p-inner-container">
                    {/* Title Section */}
                    <div className="c-p-title-container">
                        Choose Character
                    </div>
                    {/* Character Section */}
                    <div className="c-p-character-container">

                        {/* Alfie */}
                        <Link to="/starting-point" className="c-p-c-character" onClick={this.selectAlfie}>
                            { AlfieSelected === true &&
                                <div className="cpcc-character-image">
                                    <img src={ SelectedAlfie } alt="Alfie is Selected!" className="cpcc-selected" style={{opacity: '100%'}}/>
                                    <img src={ AlfieUnselected } alt="Select Alfie!" className="cpcc-unselected" style={{opacity: '0%'}}/>
                                </div>
                            }
                            { AlfieSelected === false &&
                                <div className="cpcc-character-image">
                                    <img src={ SelectedAlfie } alt="Alfie is Selected!" className="cpcc-selected" style={{opacity: '0%'}}/>
                                    <img src={ AlfieUnselected } alt='Select Alfie!' className="cpcc-unselected" style={{opacity: '100%'}} />
                                </div>
                            }
                        </Link>
                        {/* Amelia */}
                        <Link to="/starting-point" className="c-p-c-character" onClick={this.selectAmelia}>
                            { AmeliaSelected === true &&
                            <div className="cpcc-character-image">
                                <img src={ SelectedAmelia } alt="Amelia is Selected!" className="cpcc-selected" style={{opacity: '100%'}}/>
                                <img src={ AmeliaUnselected } alt="Select Amelia!" className="cpcc-unselected" style={{opacity: '0%'}}/>
                            </div>
                            }
                            { AmeliaSelected === false &&
                            <div className="cpcc-character-image">
                                <img src={ SelectedAmelia } alt="Amelia is Selected!" className="cpcc-selected" style={{opacity: '0%'}}/>
                                <img src={ AmeliaUnselected } alt='Select Amelia!' className="cpcc-unselected" style={{opacity: '100%'}} />
                            </div>
                            }
                        </Link>
                    </div>
                </div>
                {/* End of Inner Container */}
                {/* Next Button */}
                <div className="next-button-container">
                    <button onClick={this.handleChange} className="next-button">
                        <img src={NextButton} alt="" />
                    </button>
                </div>
            </div>
        );
    }
}
Character.contextType = GameContext;
export default Character;