import React, { Component } from 'react'
import SmallText from "../../images/small-text.svg";
import MediumText from "../../images/medium-text.svg";
import LargeText from "../../images/large-text.svg";
import CloseButton from '../map/assets/16/icon.svg';
import { getLocalStorageItem, setLocalStorageItem } from "../../helpers/helpers"
import './accessibility.css';


export default class Accessibility extends Component {

	handleChangeFont = (event) => {
		event.preventDefault(); //Prevents full refresh
		let elements = Array.from(document.getElementsByClassName("font-btn"));
		elements.map(element => {
			if (element === event.currentTarget) {
				element.style.borderBottom = '3px solid #1067AB';
			} else (element.style.border = 'none')
			return element
		})
		setLocalStorageItem('fontSize', event.currentTarget.value);
	}

	onClose = e => {
		this.props.onClose && this.props.onClose(e)
	}

	render() {
		let currentFontSize = getLocalStorageItem('fontSize');
		let borderBottomStyling = '3px solid #1067AB';
		let borderBottomSmall = ( currentFontSize === 'small' ? borderBottomStyling : 'none' )
		let borderBottomMedium = ( currentFontSize === 'medium' ? borderBottomStyling : 'none' )
		let borderBottomLarge = ( currentFontSize === 'large' ? borderBottomStyling : 'none' )
		if (!this.props.show) {
			return null;
		}
		return (
			<div className={" hint-popup-container"}>
				<div className="popup-container">
					<div className="popup-inner-container">
						<div className="hpb-close-button">
							<button onClick={e => {
								this.onClose(e)
							}}>
								<img src={CloseButton} alt="Close Menu" />
							</button>
						</div>
						<br/>
						<div className="accessibility-title-container">
							<h2>Accessibility Settings</h2>
						</div>
						<div className="a-font-size-container">
							<h3>Font size</h3>
							<div className="font-size-inner-container">
								<button
									style={{ borderBottom:borderBottomSmall }}
									type="submit"
									onClick={this.handleChangeFont}
									className={"font-btn small"}
									value='small'
								>
									<img src={SmallText} alt="" />
								</button>
								<button
									style={{ borderBottom:borderBottomMedium }}
									type="submit"
									onClick={this.handleChangeFont}
									className="font-btn medium"
									value='medium'
								>
									<img src={MediumText} alt="" />
								</button>
								<button
									style={{ borderBottom:borderBottomLarge }}
									type="submit"
									onClick={this.handleChangeFont}
									className="font-btn large"
									value='large'
								>
									<img src={LargeText} alt="" />
								</button>
							</div>
						</div>
						<button onClick={e => { this.onClose(e) }} className="basic-btn a-save-btn" >
							<h3>SAVE</h3>
						</button>
					</div>
				</div>
			</div>
		)
	}
}

