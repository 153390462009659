import React from "react";
import { Link } from "react-router-dom";
import './style.css';
import '../../components/map/map.css';
import lockedActivity from "../../components/quiz/assets/locked-activity.svg";
import { getLocalStorageItem } from "../../helpers/helpers";

export default class LockedToken extends React.Component{

	

    renderSwitch(param) {
        switch (param) {
            case "small": return 'class-small';
            case "large": return 'class-large';
            default: return 'class-medium';
        }
    }
    render(){

		if (!this.props.show) {
			return null;
		}
        return (
            <div className={this.renderSwitch(getLocalStorageItem("fontSize"))+" locked-popup-container"} style={{position: "fixed"}}>
                        <div className="lp-box">
                            <div className="hpb-inner-container">

                                {/* Close Button */}
                             <div className="hpb-close-button">
                                    {/* <button >
                                        <img src={CloseButton} alt="Close Menu"/>
                                    </button>*/}
                                </div>
                                {/* Body Section */}
                                <div className="hpb-body-container">
                                    {/* Title */}
                                    <div className="hpdb-inner-container hpdb-title-container">
                                        NOT THERE YET!
                                    </div>
                                    {/* Image */}
                                    <div className="hpdb-inner-container hpdb-image-container">
                                        <div className="lp-image">
                                            <img src={lockedActivity} alt="Locked"/>
                                        </div>
                                    </div>
                                    {/* Description */}
                                    <div className="hpdb-inner-container hpdb-description-container">
                                        It seems that you are not in the right era yet! Focus on unlocked activities or move to different era on the map
                                    </div>
                                    {/* Button */}
                                    <div className="hpdb-inner-container hpdb-button-container">
                                            <a className="hpdb-button" onClick={this.props.onClose}>
                                                    OK
                                            </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}