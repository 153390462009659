import React from 'react';
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/helpers'
import { Link } from "react-router-dom";
import BackButton from "../backButton/assets/back-button.svg";
import './pictureFormat.css'
import CameraPopup from '../../components/camera/camera'
import switchCamera from './assets/switchCamera.png'
import {GameContext} from '../../context/GameContext'
import Results from '../../components/results/results'


export default class Camera extends React.Component {
	constructor(props) {
		super(props);

		this.cameraNumber = 0;
		this.initializeMedia()

		this.state = {
			imageDataURL: null,
			cameraPopup: false,
			cameraError: '',
			completed: false
		};
		this.completePicture = this.completePicture.bind(this)
	}

	showCameraPopup = e => {
		this.setState({ cameraPopup: !this.state.cameraPopup })
	}

	initializeMedia = async () => {
		this.setState({ imageDataURL: null });

		if (!("mediaDevices" in navigator)) {
			navigator.mediaDevices = {};
		}

		if (!("getUserMedia" in navigator.mediaDevices)) {
			navigator.mediaDevices.getUserMedia = function (constraints) {
				var getUserMedia =
					navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

				if (!getUserMedia) {
					return Promise.reject(new Error("getUserMedia Not Implemented"));
				}

				return new Promise((resolve, reject) => {
					getUserMedia.call(navigator, constraints, resolve, reject);
				});
			};
		}

		//Get the details of video inputs of the device
		const videoInputs = await this.getListOfVideoInputs();

		//The device has a camera
		if (videoInputs.length) {
			navigator.mediaDevices
				.getUserMedia({
					video: {
						deviceId: {
							exact: videoInputs[this.cameraNumber].deviceId,
						},
					},
				})
				.then((stream) => {
					this.player.srcObject = stream;
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			this.setState({ cameraError: "The device does not have a camera" })
			this.showCameraPopup()
			//alert("The device does not have a camera");
		}
	};

	capturePicture = () => {
		var canvas = document.createElement("canvas");
		canvas.width = this.player.videoWidth;
		canvas.height = this.player.videoHeight;
		var contex = canvas.getContext("2d");
		contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
		this.player.srcObject.getVideoTracks().forEach((track) => {
			track.stop();
		});

		this.setState({ imageDataURL: canvas.toDataURL() });
	};

	switchCamera = async () => {
		const listOfVideoInputs = await this.getListOfVideoInputs();

		// The device has more than one camera
		if (listOfVideoInputs.length > 1) {
			if (this.player.srcObject) {
				this.player.srcObject.getVideoTracks().forEach((track) => {
					track.stop();
				});
			}

			// switch to second camera
			if (this.cameraNumber === 0) {
				this.cameraNumber = 1;
			}
			// switch to first camera
			else if (this.cameraNumber === 1) {
				this.cameraNumber = 0;
			}

			// Restart based on camera input
			this.initializeMedia();
		} else if (listOfVideoInputs.length === 1) {
			this.setState({ cameraError: "The device has only one camera" })
			this.showCameraPopup()
			//alert("The device has only one camera");
		} else {
			this.setState({ cameraError: "The device does not have a camera" })
			this.showCameraPopup()
			//alert("The device does not have a camera");
		}
	};

	getListOfVideoInputs = async () => {
		// Get the details of audio and video output of the device
		const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

		//Filter video outputs (for devices with multiple cameras)
		return enumerateDevices.filter((device) => device.kind === "videoinput");
	};
	completePicture() {
		this.context.updateScore(this.props.location.state.tokenScore)
		this.context.completeToken(getLocalStorageItem("current-token"))
		this.setState({ completed: true })
	}

	render() {
		console.log("Token Points", )
		const activityDetails = this.props.location.state.activityDetails;
		let eras
		let eraKeys
		let currentEra
		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries') {
			eras = getLocalStorageItem('aeroEras')
			eraKeys = getLocalStorageItem('aeroEraKeys')
			currentEra = eraKeys[getLocalStorageItem('eraIndex')];
		} else {
			eras = getLocalStorageItem('concordeEras')
			eraKeys = getLocalStorageItem('concordeEraKeys')
			currentEra = eraKeys[getLocalStorageItem('concordeIndex')];
		}
		const eratokens = eras[currentEra].tokens
		const tokenCompleted = Object.keys(eratokens).filter(function (child) {
			if (eratokens[child].uuid === (activityDetails.activity_token)) {
				return eratokens[child];
			}
			return null;
		});
		setLocalStorageItem("current-token", tokenCompleted)

		const playerORImage = Boolean(this.state.imageDataURL) ? (
			<img className="image_preview" src={this.state.imageDataURL} alt="cameraPic" />
		) : (
			<video
				ref={(reference) => {
					this.player = reference;
				}}
				playsInline
				autoPlay
			></video>

		);
		const CaptureDownloadbutton = Boolean(this.state.imageDataURL) ? (
			<a className="camera mbp-picture-button" download="picture.jpg" href={this.state.imageDataURL}>SAVE</a>
		) : (

			<button className="camera mbp-picture-button" onClick={this.capturePicture}>Take A Picture</button>
		);

		const ContinueButton = Boolean(this.state.imageDataURL) ? (
			<button onClick={this.completePicture} className="camera mbp-picture-button">Continue</button>
		) :
			(
				<button onClick={this.completePicture} className="camera mbp-cancel-picture-button">Cancel</button>
			);
		return (


			<div className="App camera_feed">
				<CameraPopup onClose={this.showCameraPopup} show={this.state.cameraPopup} description={this.state.cameraError} />

				<div className="back-btn">
					<button>
						<Link
							to="/game-menu">
							<img src={BackButton} alt="" />
						</Link>
					</button>
				</div>

				{playerORImage}

				{CaptureDownloadbutton}
				<button className="camera camera-switch" onClick={this.switchCamera}><img height="20px" src={switchCamera} alt="switch camera" /></button>
				{ContinueButton}
				{ this.state.completed === true &&
					<Results
						score={this.props.location.state.tokenScore}
						withProgressBar={true}
					/>
				}
			</div>
		);
	}
}
Camera.contextType = GameContext