import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

import Splash from "./screens/splash/splash";
import Welcome from "./screens/welcome/welcome";
import PlayerInfo from "./screens/playerInfo/playerInfo";
import Character from "./screens/character/character";
import StartingPoint from "./screens/startingPoint/startingPoint";
// pop-ups!!!
import PopupLockedToken from "./screens/pop-up/lockedToken";
import PopupSpin from "./components/spinGame/spinGame";

import Ready from "./screens/ready/ready";
import TermsConditions from "./screens/termsConditions/termsConditions";
import GameMenu from "./screens/gameMenu/gameMenu";
import FactFormat from "./components/fact/factFormat";
import SectionQuiz from "./components/quiz/quizStructure/quizStructure";

import GameGuide from "./screens/gameGuide/gameGuide";
import Leaderboard from "./screens/leaderboard/leaderboard";
import EndingScreen from "./screens/endingScreen/endingScreen";
import RecapQuiz from "./screens/recap/recap";
import Location from "./screens/pop-up/location";
import Camera from './components/picture/Camera'

import GameProvider from './context/GameContext';
import error from "./screens/error/error";
import Certificate from "./screens/certificate/certificate";

export default function App() {

	return (

		<BrowserRouter>
			<DeviceOrientation lockOrientation={'portrait'}>
				{/* Will only be in DOM in landscape */}
				<Orientation orientation='landscape' alwaysRender={false}>
					<div style={{marginTop:'10rem', textAlign:'center'}}>
						<h2>Please rotate your device</h2>
					</div>
				</Orientation>
				<Orientation orientation='portrait' alwaysRender={false}>
					<div className="container">
						<Switch>


							<Route path="/" exact={true} component={Splash} />
							
							<Route path="/welcome"  component={Welcome} />
							
							<GameProvider>
								<Route path="/player-info" component={PlayerInfo} />

								<Route path="/character" component={Character} />

								<Route path="/camera" component={Camera} />

								<Route path="/starting-point" component={StartingPoint} />

								<Route path="/ready" component={Ready} />

								<Route path="/ts-and-cs" component={TermsConditions} />

								<Route path="/popup-locked-token" component={PopupLockedToken} />

								<Route path="/change-zone" component={Location} />

								<Route path="/popup-spin" component={PopupSpin} />

								{/* Quiz Pages */}

								<Route path="/section-quiz" component={SectionQuiz} />


								{/* Picture Pages */}

								<Route path="/section-one-pic" component={Camera} />

								{/* End of Picture Pages */}

								<Route path="/fact" component={FactFormat} />

								<Route path="/user-guide" component={GameGuide} />

								<Route path="/leaderboard" component={Leaderboard} />

								<Route path="/recap" component={RecapQuiz} />

								<Route path="/outro" component={EndingScreen} />

								<Route path="/oops-error" component={error} />

								<Route path="/game-menu" component={GameMenu} />

								<Route path="/certificate" component={Certificate} />

							</GameProvider>

							
						</Switch>
					</div>
				</Orientation>
			</DeviceOrientation>

		</BrowserRouter>
	);

}
