import React, { Component } from "react";
import { Link } from "react-router-dom";


import {GameContext} from '../../context/GameContext'



export default class error extends Component {
	static contextType = GameContext
    
  

    render(){

		const { getError } = this.context
	
   
    return (
        
        <div className="container p-info-container"> {/* Page Container */}
            {/* Back Button */}
            {/* Body Container */}
            <div className="player-inner-container">
                {/* Title Section  */}
                <div className="p-i-title-container">
                    Oops! We have an error. 
                </div>
			</div>
				Return home and try again.
			  	Error = {getError()}
               
            {/* Next Button */}
            <div className="next-button-container">
                <Link to="/"
                    className="next-button">
                    Return Home
                </Link>
                
            </div>
        </div>
    )
}
}