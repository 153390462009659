import React, { useState } from "react";
import './location.css';
import pinIcon from "./assets/locationPin.svg";
import Location from '../../screens/pop-up/location'
// Back button works by accessing history via React Router
const LocationPin = (props) => {
	const [show, setShow] = useState(false)
	const currentZone = props.era;
	const mainZones = props.map['aerospace-galleries']['eras'];
	const concordeZones = props.map['concorde-hangar']['eras'];

	return (
		<div className="location-btn">
			<button style={{zIndex: 99}} >
				<img src={pinIcon} alt="Change Era Zone" onClick={() => setShow(true)} />
				<Location onClose={() => setShow(false)} show={show} currentZone={currentZone} mainZones={mainZones} concordeZones={concordeZones} />
			</button>
		</div>
	)
}

export default LocationPin;

