import React, {Component} from 'react';
import {Link} from "react-router-dom";
import './ready.css';
import PlaneVector from './assets/plane-vector.svg';
import Back from "../../components/backButton/back";
import game from "../global-var";
import Axios from "axios"
import { getLocalStorageItem } from '../../helpers/helpers'

Axios({
    method: 'post',
    url: process.env.REACT_APP_SERVER_DOMAIN_MAIN + 'data/get-map',
}).then((response) => {
game.map = response.data;

});
class Ready extends Component {
    renderSwitch(param) {
        switch (param) {
            case "small": return 'class-small';
            case "large": return 'class-large';
            default: return 'class-medium';
        }
    }
    render () {
        return (
        <div className={this.renderSwitch(getLocalStorageItem("fontSize"))+" container ready-container"}>

            {/* Back Button */}
            <div className="back-button">
                <Back className="back-button"/>
            </div>

            <div className="ready-inner-container">

                {/* Title Container */}
                <div className="r-title-container">
                    Clear For Take-Off!
                </div>

                {/* Description Container */}
                <div className="r-description-container">
                    Get ready to start your adventure and collect as many points as you can
                </div>

                {/* Image Container */}
                <div className="r-image-container r-image-animated">
                    <img src={PlaneVector} alt=""/>
                </div>

                {/* Continue button*/}
                <div className="r-continue-container">
                    <Link to="/game-menu" className="basic-btn r-continue">
                        <h3>Start your adventure</h3>
                    </Link>
                </div>

            </div>
        </div>
        );
    }
}
export default Ready;