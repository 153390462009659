import React, { Component } from 'react';
import './quizStructure.css';
import { Link } from 'react-router-dom';
import HintButton from '../assets/hint-button.png';
import game from '../../../screens/global-var';
import NextButton from "../../../images/next-button.svg";
import BackButton from "../../backButton/assets/back-button.svg";
import { getLocalStorageItem, setLocalStorageItem } from '../../../helpers/helpers';
import { GameContext } from '../../../context/GameContext';
import Results from '../../../components/results/results'

// Question Text/Answer Button Component
class Question extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hintPopup: false,
			resultPopup: false,
			answerPopup: false,
			answer: false,
			completed: false,//if true show confetti
			answerShown: false,
			usedHint: false,
			score: Number(this.props.tokenScore),  // actual score at end
			fullScore: Number(this.props.tokenScore), // full score with no deductions
			wrongClicked: 0,
			hintShown: false,
			nb: 0, // what is this?
			surePopup: false,
			emptyAnswer: true,
			emptyPopup: false,
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		this.setState({
			emptyAnswer: false
		})
		e.preventDefault(); //Prevents full refresh
		let elements = Array.from(document.getElementsByClassName("answer-btn"));
		elements.map(element => {
			if (element !== e.target) {
				element.style.backgroundColor = 'white';
			}
			else {
				e.target.style.backgroundColor = '#C0EBF4';
			}
			return element;
		})
		this.props.onChoiceChange(e.target.value);
		if (this.props.question.option_correct.key === e.target.value) {
			this.setState({
				answer: true
			})
		} else {
			this.setState({ answer: false })
		}
	}

	calculateScore() {
		// discountFactor is a multiplication factor on the full score
		// which is 1-%off. E.g. 40% off is discountFactor of 0.6
		let discountFactor = 1;
		// 40% off for hint shown = discountFactor
		if (this.state.hintShown===true)
			discountFactor *= 0.6;
		// 50% off if wrong clicked once
		if (this.state.wrongClicked === 1)
			discountFactor *= 0.5;
		// if wrong twice or answer shown full 90% off
		if (this.state.wrongClicked >= 2 || this.state.answerShown === true)
			discountFactor = 0.1;
		let score = Math.ceil(this.state.fullScore * discountFactor);
		return score;
	}

	calculateScoreAndComplete() {
		let score = this.calculateScore();
		this.setState({
			score: score,
			completed: true
		});
		this.context.updateScore(score);
		this.context.completeToken(getLocalStorageItem("current-token"));
	}

	toggleHintPopup() {
		let elements = Array.from(document.getElementsByClassName("answer-btn"));
		elements.map(element => {
			element.style.backgroundColor = 'white';
			return element;
		})
		this.setState({
			hintPopup: !this.state.hintPopup,
		})
		if (this.state.hintPopup === true) {
			this.setState({
				hintShown: true
			})
		}
	}
	toggleSurePopup() {
		if (this.state.emptyAnswer === true) {
			this.setState({
				emptyPopup: !this.state.emptyPopup,
			})
		} else {
			this.setState({
				surePopup: !this.state.surePopup,
			})
		}
	}

	toggleEmptyPopup() {
		this.setState({
			emptyPopup: !this.state.emptyPopup,
		})
	}

	toggleResultPopup() {
		if (this.state.surePopup === true)
			this.setState({
				surePopup: false,
			})
		if (this.state.wrongClicked >= 2) {
			this.calculateScoreAndComplete();
		}
		if (this.state.answer === false) {
			if (this.state.resultPopup === false) {
				this.setState({
					wrongClicked: this.state.wrongClicked + 1
				});
			}
			this.setState({
				resultPopup: !this.state.resultPopup,
			})
		} else {
			this.calculateScoreAndComplete();
		}
	}

	toggleAnswerPopup() {
		if (this.state.answerPopup === false) {
			this.setState({
				answerShown: true,
				answerPopup: true,
				resultPopup: false
			})
		} else if (this.state.answerPopup === true) {
			this.calculateScoreAndComplete();
			this.setState({
				answerPopup: false,
			})
		}
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	render() {
		const question = this.props.question; // Variable set to equal question from props
		const isHintShown = this.state.hintPopup;
		const isResultShown = this.state.resultPopup;
		const isAnswerShown = this.state.answerPopup;
		const hintImg = process.env.REACT_APP_SERVER_DOMAIN + 'firefly/file/img?id=' + question.hint_image + '&w=400';
		const answer = question[this.props.question.option_correct.key];
		const isSureShown = this.state.surePopup;
		const isEmptyShown = this.state.emptyPopup;
		const quizImg = process.env.REACT_APP_SERVER_DOMAIN + 'firefly/file/img?id=' + question.image + '&w=400';

		const HintPopup = () => {
			return (
				<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " hint-popup-container"}>
					<div className="h-p-box-container">
						<div className="hpb-inner-container">
							<div className="hpb-close-button"></div>
							{/* Body Section */}
							<div className="hpb-body-container">
								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									HINT TIME!
								</div>

								{/* Image */}
								{
									(question.hint_image != null) &&
									<div className="hpdb-inner-container hpdb-image-container">
										<div className="hpdbi-image">
											<img src={hintImg} alt='hint' />
										</div>
									</div>
								}

								{/* Description */}
								{
									(question.hint != null) &&
									<div className="hpdb-inner-container hpdb-description-container">
										{question.hint}
									</div>
								}

								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container">
									<button className="hpdb-button" onClick={() => this.toggleHintPopup()}>
										OK
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		const ResultPopup = () => {
			return (
				<div className="hint-popup-container">
					<div className="h-p-box-container result-answer">
						<div className="hpb-inner-container">
							<div className="hpb-close-button"></div>
							{/* Body Section */}
							<div className="hpb-body-container">
								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									{
										(this.state.wrongClicked < 2) ?
											'YOU ARE ON THE RIGHT TRACK, BUT NOT THERE YET!'
											:
											<div className="container-buttons">
												SO CLOSE, THE ANSWER IS:
												<p className="btn-text" style={{ fontWeight:'bold' }}>{answer}</p>
												<p className="btn-text">Have { Math.ceil(this.state.fullScore * 0.1) } points for trying<br/>and better luck next time!</p>
											</div>
									}
								</div>
								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container">
									{
										(this.state.wrongClicked < 2) ?
											<div className="container-buttons">
												<p className="btn-text">Tries remaining:1</p>
												<button className="hpdb-button" style={{ width:'100%' }} onClick={() => this.toggleResultPopup()}>
													TRY AGAIN
												</button>
												<p className="btn-text">Just want to move forward?</p>
												<button className="hpdb-button alert"  style={{ width:'100%' }} onClick={() => this.toggleAnswerPopup()}>
													SHOW ANSWER
												</button>
											</div>
											:
											<div className="hpdb-inner-container hpdb-button-container">
												<button className="hpdb-button" style={{width:'50%'}} onClick={()=>this.toggleResultPopup()}>OK</button>
											</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const AnswerPopup = () => {
			return (
				<div className="hint-popup-container">
					<div className="h-p-box-container result-answer">
						<div className="hpb-inner-container">
							<div className="hpb-close-button"></div>
							{/* Body Section */}
							<div className="hpb-body-container">
								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									THE ANSWER YOU ARE<br/>LOOKING FOR IS:
									<p className="btn-text" style={{ fontWeight:'bold' }}>{answer}</p>
									<p className="btn-text">Have { Math.ceil(this.state.fullScore * 0.1) } points for trying<br/>and better luck next time!</p>
								</div>
								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container">
									<button onClick={() => this.toggleAnswerPopup()} className="hpdb-button" >
										OK
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		const SurePopup = () => {
			return (
				<div className="hint-popup-container">
					<div className="h-p-box-container quit-container">
						<div className="hpb-inner-container">
							<div className="hpb-close-button"></div>
							{/* Body Section */}
							<div className="hpb-body-container">
								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									ARE YOU SURE?
								</div>
								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container quit-container">
									<button className="hpdb-button alert quit-btn" onClick={() => this.toggleSurePopup()} >
										NO
									</button>
									<button className="hpdb-button quit-btn" onClick={() => this.toggleResultPopup()}>
										YES
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		const EmptyPopup = () => {
			return (
				<div className="hint-popup-container">
					<div className="h-p-box-container quit-container">
						<div className="hpb-inner-container">
							<div className="hpb-close-button">
							</div>
							{/* Body Section */}
							<div className="hpb-body-container">

								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									DON'T FORGET TO PICK YOUR ANSWER
								</div>
								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container quit-container">
									<button className="hpdb-button quit-btn" onClick={() => this.toggleEmptyPopup()} >
										OK
									</button>
								</div>

							</div>

						</div>
					</div>
				</div>
			)
		}
		return (
			<div>

				{/* Hint Popup*/}
				{
					isHintShown === true &&
					<HintPopup />
				}
				{
					isResultShown === true &&
					<ResultPopup />
				}
				{
					isAnswerShown === true &&
					<AnswerPopup />
				}

				{
					isSureShown === true &&
					<SurePopup />
				}
				{
					isEmptyShown === true &&
					<EmptyPopup />
				}

				{/* Question Layout */}
				<div className="question-component">
					{question.content}
				</div>
				{question.image !== null ?
					<div className="quiz-image">
						<img src={quizImg} alt="" />
					</div>
					: null
				}
				<div className={(question.image !== null ? "answer-container-under-image " : '') + "answer-container"}>
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_1'
						className="answer-btn"
						style={{ height: question.image !== null ? '15vh' : '26vh' }}
					>
						{question.option_1}
					</button>
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_2'
						className="answer-btn"
						style={{ height: question.image !== null ? '15vh' : '26vh' }}
					>
						{question.option_2}
					</button>
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_3'
						className="answer-btn"
						style={{ height: question.image !== null ? '15vh' : '26vh' }}
					>
						{question.option_3}
					</button>
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_4'
						className="answer-btn"
						style={{ height: question.image !== null ? '15vh' : '26vh' }}
					>
						{question.option_4}
					</button>
					{
						(question.hint != null || question.hint_image != null) &&
						<button className="hint-btn" onClick={() => this.toggleHintPopup()}>
							<img src={HintButton} alt="Hint Button" />
						</button>
					}
				</div>
				<div className="next-button-container">
					<button className="next-button" onClick={() => this.toggleSurePopup()}>
						<img src={NextButton} alt="" />
					</button>
				</div>

				{
					this.state.completed === true &&
					<Results
						score={this.state.score}
						withProgressBar={true}
					/>

				}

			</div>

		)
	}
}
Question.contextType = GameContext

// Final Quiz Format Section
export default class QuizFormat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quitPopup: false,
		};
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(choice) {
	}

	toggleQuitPopup() {
		this.setState({
			quitPopup: !this.state.quitPopup,
		})
	}
	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	render() {
		console.log('location states', this.props.location.state);
		//from link
		const activityDetails = this.props.location.state.activityDetails;
		const tokenScore = this.props.location.state.tokenScore;
		//from global variable
		game.map = getLocalStorageItem('map')
		let eras
		let eraKeys
		let currentEra
		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries') {
			eras = getLocalStorageItem('aeroEras')
			eraKeys = getLocalStorageItem('aeroEraKeys')
			currentEra = eraKeys[getLocalStorageItem('eraIndex')];

		} else {
			eras = getLocalStorageItem('concordeEras')
			eraKeys = getLocalStorageItem('concordeEraKeys')
			currentEra = eraKeys[getLocalStorageItem('concordeIndex')];

		}
		const eratokens = eras[currentEra].tokens

		const currentToken = Object.keys(eratokens).filter(function (child) {
			if (eratokens[child].uuid === (activityDetails.activity_token)) {
				return eratokens[child];
			}
			return null;
		});
		setLocalStorageItem("current-token", currentToken)

		const isQuitShown = this.state.quitPopup;
		const QuitPopup = () => {
			return (
				<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " hint-popup-container"}>
					<div className="h-p-box-container quit-container">
						<div className="hpb-inner-container">
							<div className="hpb-close-button"></div>
							{/* Body Section */}
							<div className="hpb-body-container">

								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									DO YOU WANT TO GO BACK?
								</div>

								{/* Description */}
								{
									<div className="hpdb-inner-container hpdb-description-container">
										<p>Your points won't be added!</p>
									</div>
								}

								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container quit-container">
									<button className="hpdb-button alert quit-btn" >
										<Link to="game-menu" style={{ color: 'white' }} >
											YES
										</Link>
									</button>
									<button className="hpdb-button quit-btn" onClick={() => this.toggleQuitPopup()}>
										NO
									</button>
								</div>

							</div>

						</div>
					</div>
				</div>
			)
		}
		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " quiz-container"}>
				{
					isQuitShown === true &&
					<QuitPopup />
				}
				<div className="back-btn"> {/* Back Button Container */}
					<button onClick={() => this.toggleQuitPopup()}>
						<img src={BackButton} alt="" />
					</button>
				</div>

				<Question
					question={activityDetails}
					onChoiceChange={this.handleChange}
					tokenScore={tokenScore}
					token={eratokens[currentToken]}
				/>
			</div>
		);
	}
}


// End of Final Quiz Format Section
