import React, { Component } from 'react';
import './factFormat.css';
import { Link } from 'react-router-dom';
import NextButton from '../../images/next-button.svg';
import BackButton from "../backButton/assets/back-button.svg";
import { GameContext } from "../../context/GameContext"
import { getLocalStorageItem, setLocalStorageItem } from "../../helpers/helpers"
import Results from '../../components/results/results'

class FactComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quitPopup: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.toggleQuitPopup = this.toggleQuitPopup.bind(this)
	}

	handleChange(e) {
		e.preventDefault();
		this.props.onNextButton(e.target.value);
		this.context.updateScore(this.props.tokenScore)
		//game.score=Number(this.props.tokenScore)+Number(game.score) ;
		this.props.token.complete = true;
	}
	toggleQuitPopup() {
		this.setState({
			quitPopup: !this.state.quitPopup,
		})
	}
	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	render() {
		const fact = this.props.fact;
		console.log("fact", fact);
		const factImg = process.env.REACT_APP_SERVER_DOMAIN + 'firefly/file/img?id=' + fact.image + '&w=400';
		const isQuitShown = this.state.quitPopup;
		const QuitPopup = () => {
			return (
				<div className="hint-popup-container">
					<div className="h-p-box-container quit-container">
						<div className="hpb-inner-container">
							<div className="hpb-close-button"></div>
							{/* Body Section */}
							<div className="hpb-body-container">
								{/* Title */}
								<div className="hpdb-inner-container hpdb-title-container">
									DO YOU WANT TO GO BACK?
								</div>
								{/* Description */}
								{
									<div className="hpdb-inner-container hpdb-description-container">
										Your points won't be added!
									</div>
								}
								{/* Button */}
								<div className="hpdb-inner-container hpdb-button-container quit-container">
									<button className="hpdb-button alert quit-btn" >
										<Link to="game-menu" style={{ color: 'white' }} >
											YES
										</Link>
									</button>
									<button className="hpdb-button quit-btn" onClick={() => this.toggleQuitPopup()}>
										NO
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " container fact-screen-container"}>
				<div className="inner-container fact-inner-container">
					<h2>Did You Know?</h2>
					{fact.image !== '' ?
						<div className="fact-image">
							<img src={factImg} alt="" />
						</div>
						: null
					}
					<div className="fact-description" value=''>
						<p>{fact.content}</p>
					</div>
					<div className="next-button-container">
						<Link
							to="/game-menu"
							className="next-button"
							onClick={this.handleChange}
							name=''
							value=''
						>
							<img src={NextButton} alt="" />
						</Link>
					</div>
				</div>
			</div>
		)
	}
}
FactComponent.contextType = GameContext;

export default class FactFormat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activityDetails: { rows: {} },
			tokenScore: 0,
			token: {},
			completed: false
		}

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange() {
		this.setState((prevState) => ({
			completed: true

		}))
		this.context.completeToken(getLocalStorageItem("current-token"))
	}

	render() {

		const activityDetails = this.props.location.state.activityDetails;
		const tokenScore = this.props.location.state.tokenScore;
		let eras
		let eraKeys
		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries') {
			eras = getLocalStorageItem('aeroEras')
			eraKeys = getLocalStorageItem('aeroEraKeys')
		} else {
			eras = getLocalStorageItem('concordeEras')
			eraKeys = getLocalStorageItem('concordeEraKeys')
		}
		let currentEra
		if (getLocalStorageItem("currentBuilding") === "concorde-hangar") {
			currentEra = eraKeys[getLocalStorageItem('concordeIndex')];
		} else {
			currentEra = eraKeys[getLocalStorageItem('eraIndex')];
		}
		const eratokens = eras[currentEra].tokens
		const tokenCompleted = Object.keys(eratokens).filter(function (child) {
			if (eratokens[child].uuid === (activityDetails.activity_token)) {
				return eratokens[child];
			}
			return null
		});
		setLocalStorageItem("current-token", tokenCompleted)

		return (
			<div className="container fact-container">

				{/* {this.state.current <= facts.length && */}
				<FactComponent
					fact={activityDetails}
					tokenScore={tokenScore}
					token={eratokens[tokenCompleted]}
					onNextButton={this.handleChange}
				/>
				{/* } */}
				{this.state.completed === true &&
					<Results
						score={tokenScore}
						withProgressBar={true}
					/>
				}

			</div>
		);
	}
}
FactFormat.contextType = GameContext
