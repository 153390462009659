/* global */
import React, { Component } from 'react';
import MapOverlay from './assets/main-building.svg';
import unlockFact from './assets/adventure-unlocked-fact.svg';
import unlockPhoto from './assets/adventure-unlocked-photo.svg';
import unlockQuiz from './assets/adventure-unlocked-quiz.svg';
import lock from './assets/adventure-locked.svg';
import completed from './assets/adventure-completed.svg';
import './map.css';
import './map2.css';
import game from "../../screens/global-var";
import { Link } from 'react-router-dom';
import LocationPin from '../locationButton/location';
import { getLocalStorageItem } from '../../helpers/helpers';
import Header from '../header/header.js';
import ConcordeOverlay from './assets/concorde-hanger.svg'
import { GameContext } from '../../context/GameContext';
import { Redirect } from 'react-router-dom';
import ExitButton from '../exitButton/exitGame';
import GameDonePopup from '../gameDone/gameDone';
import GalleriesPopup from '../galleries/galleries';
import ConcordePopup from '../concorde/concorde';
import SpinGamePopup from '../spinGame/spinGame'
import LockedTokenItem from '../../components/lockedToken/lockedToken'


/**
 * Changing of locations / zones or eras (too many names for the same thing)
 * can be achived by using a get parameter within th url ?era=1. The getEraParam
 * function will look for this and return the era code to be set in the
 * game.currentEra as a string.
 */
function getEraParam() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const era = urlParams.get('era');
	if (era === null) return null;
	if (era.length < 3) {
		return 'era-' + era

	} else {
		return era
	}

}

function viewport() {
	var e = window, a = 'inner';
	if (!('innerWidth' in window)) {
		a = 'client';
		e = document.documentElement || document.body;
	}
	return { width: e[a + 'Width'], height: e[a + 'Height'] }
}

export default class Map extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirect: false,
			galleriesPopup: false,
			concordePopup: false,
			gameCompletePopup: false
		}
	}

	showGalleriesModal = e => {
		this.setState({galleriesPopup: !this.state.galleriesPopup})
	}

	showConcordeModal = e => {
		this.setState({concordePopup: !this.state.concordePopup})
	}

	showGameCompleteModal = e => {
		this.setState({gameCompletePopup: !this.state.gameCompletePopup})
	}


	handleSubmit = (event) => {

	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}

	render() {
		if (getEraParam() != null) {
			this.context.changeLocation(getEraParam())
		}

		this.context.checkZoneComplete()

		this.context.isBuildingComplete()
		if (getLocalStorageItem("galleries_completed") && getLocalStorageItem("concorde_completed")) {
			if (!this.state.gameCompletePopup)
				this.showGameCompleteModal()
		}
		else if (getLocalStorageItem("galleries_completed") && getLocalStorageItem("currentBuilding") === "aerospace-galleries") {
			if(!this.state.concordePopup)
				this.showConcordeModal()
		}
		else if (getLocalStorageItem("concorde_completed") && getLocalStorageItem("currentBuilding") === "concorde-hangar")
		{
			if (!this.state.galleriesPopup)
				this.showGalleriesModal()
		}

		let eras;
		let eraKeys;
		if (getLocalStorageItem('currentBuilding') === 'aerospace-galleries') {
			eras = getLocalStorageItem('aeroEras')
			eraKeys = getLocalStorageItem('aeroEraKeys')
		} else {
			eras = getLocalStorageItem('concordeEras')
			eraKeys = getLocalStorageItem('concordeEraKeys')
		}
		let buildings = getLocalStorageItem('buildings')
		const { redirect } = this.state;
		if (redirect) {
			return <Redirect to='/leaderboard' />;
		}
		let currentEra;
		if(getLocalStorageItem("currentBuilding") === "concorde-hangar")
		{
			currentEra = eraKeys[getLocalStorageItem('concordeIndex')];

		} else {
			currentEra = eraKeys[getLocalStorageItem('eraIndex')];
		}
		let nbEraCompleted = 0;

		let build = '';
		if (getLocalStorageItem("currentBuilding") === 'concorde-hangar')
			build = ConcordeOverlay;
		else
			build = MapOverlay;

		if (getEraParam() != null) {
			game.currentEra = getEraParam()
		}
		let currentEraX = getLocalStorageItem("currentEraX");
		let currentEraY = getLocalStorageItem("currentEraY");

		const mapWidth = 450;
		const mapHeight = 655;
		let mapScale = 1.8;

		const viewportSize = viewport();
		let markerSize = 64;
		if (getLocalStorageItem("currentBuilding") === "aerospace-galleries") {
			if (viewportSize.width <= 280) {//FOLD
				mapScale = 1.3;
				markerSize = 45;
			}
			else if (viewportSize.width < 350) {//5SE
				mapScale = 1.58;
				markerSize = 45;
			}
			else if (viewportSize.width < 500) {
				if (viewportSize.height <= 667) {
					if (viewportSize.height > 640) {//iphone 6/7/8
						mapScale = 1.7;
						markerSize = 57;
					}
					else {								//GALAXY S5
						mapScale = 1.85;
						markerSize = 55;
					}
				} else {					//Pixel2 , //iphone6/7/8 plus
					mapScale = 1.8;
					markerSize = 64;
				}
			}
			else if (viewportSize.height > 800) {
				if (viewportSize.width >= 1024) {//ipad pro
					mapScale = 3.5;
					markerSize = 105;
				}
				else if (viewportSize.width >= 768) {//ipad
					mapScale = 2.5;
					markerSize = 80;
				} else {							//pixel2XL, IPhoneX
					mapScale = 1.89;
					markerSize = 68;
				}
			}
			else {									//duo
				mapScale = 1.7;
				markerSize = 62;
			}
		}
		if (getLocalStorageItem("currentBuilding") === "concorde-hangar") {
			if (viewportSize.width < 300) {    //FOLD
				mapScale = 0.8;
				markerSize = 45;
			}
			else if (viewportSize.width < 350) {  //Iphone5/SE
				mapScale = 0.9;
				markerSize = 45;
			} else if (viewportSize.width > 1000) {	//ipad pro
				mapScale = 2.2;
				markerSize = 105;

			} else if (viewportSize.width > 700) {	//ipad
				mapScale = 1.6;
				markerSize = 80;

			} else if (viewportSize.width > 400) {	//Pixel2,pixel2xl,iphone 6/7/8 plus, surface duo
				mapScale = 1.24;
				markerSize = 64;

			} else if (viewportSize.width > 350) { //iphone 6/7/8
				mapScale = 1.1;
				markerSize = 57;
			}
			else {
				mapScale = 1;
				markerSize = 62;
			}
		}
		const renderSwitch = (param) => {
			switch (param) {
				case 'quiz':
					return unlockQuiz;
				case 'fact':
					return unlockFact;
				case 'picture':
					return unlockPhoto;
				default:
					return unlockFact;
			}
		}

		return (
			<div>
				{

				}

				{<Header character={getLocalStorageItem("character")} score={getLocalStorageItem('score')} />}
				<LocationPin map={buildings} era={currentEra} />


				<div style={{
					width: "100vw",
					height: "100vh",
					position: "absolute",
					overflow: "hidden",
					top: 0,
					left: 0
				}}>
					<GameDonePopup onClose={this.showGameCompleteModal} show={this.state.gameCompletePopup} />
					<GalleriesPopup onClose={this.showGalleriesModal} show={this.state.galleriesPopup} />
					<ConcordePopup onClose={this.showConcordeModal} show={this.state.concordePopup} />
					<SpinGamePopup show={getLocalStorageItem('spinShow')} />

					<ExitButton />

					<div className="mapAnimation"
						style={{
							height: mapScale * mapHeight,
							width: mapScale * mapWidth,
							position: "relative",
							left: 'calc(50vw - ' + mapScale * mapWidth * currentEraX / 100 + 'px)',
							top: 'calc(43vh - ' + mapScale * mapHeight * currentEraY / 100 + 'px)'

						}}
					>
						<img id="building" style={{ height: mapScale * mapHeight, width: mapScale * mapWidth, position: "absolute" }} src={build} alt='building' />
						{
							Object.keys(eras).map(function (eraKey) {//tokens of one era
								//building completed
								if (eras[eraKey].complete === true) {
									nbEraCompleted = nbEraCompleted + 1;
									console.log(' era in building', nbEraCompleted, eraKeys.length);
								}
								if (nbEraCompleted === eraKeys.length) {
									(game.map.buildings[game.currentBuilding].complete) = true;

									if ((game.map.buildings[game.currentBuilding].complete) === true) {
										//show pop up to move to new building
										console.log('new build', game.currentBuilding);
									}
									if ((game.map.buildings['aerospace-galleries'].complete) === true && (game.map.buildings['concorde-hangar'].complete) === true) {
										//	alert('both completed, go to leaderboard')
									}
									console.log(game);
								}

								const tokens = eras[eraKey].tokens;
								return (
									<div key={eraKey} className={eraKey}> {
										Object.keys(tokens).map(function (tokenKey) {
											let token = tokens[tokenKey];
											let tokenPath = '';
											switch(token.type) {
												case 'quiz': tokenPath='/section-quiz'; break;
												case 'fact': tokenPath='/fact'; break;
												case 'picture': tokenPath='/section-one-pic'; break;
												default: tokenPath='/'; break;
											}
											let image = (token.complete === true ? completed : (eraKey !== currentEra) ? lock : renderSwitch(token.type));
											return (
												(token.complete === true) ?
													<img key={token.uuid} src={image} id="marker" alt=''
														style={{
															position: "absolute",
															width: markerSize + "px",
															top: ((mapHeight * mapScale) / 100) * token.position_y - (markerSize / 2),
															left: ((mapWidth * mapScale) / 100) * token.position_x - (markerSize / 2)
														}} />
													: (eraKey !== currentEra) ?
														//locked
														// <Link key={token.uuid} to='/popup-locked-token'>
														// 	<img src={image} id="marker" alt=''
														// 		style={{
														// 			position: "absolute",
														// 			width: markerSize + "px",
														// 			top: ((mapHeight * mapScale) / 100) * token.position_y - (markerSize / 2),
														// 			left: ((mapWidth * mapScale) / 100) * token.position_x - (markerSize / 2)
														// 		}} />
														// </Link>
														<LockedTokenItem image={image} markerSize={markerSize} mapHeight={mapHeight} mapScale={mapScale} mapWidth={mapWidth} token={token}/>
														//active
													: <Link key={token.uuid} to={{
															pathname: tokenPath,
															state: {
																type: token.type,
																id: token.uuid,
																tokenScore: token.score_medium_level,
																activityDetails: token.activity
															}
														}}>
														<img src={image} id="marker" alt=''
															style={{
																position: "absolute",
																width: markerSize + "px",
																top: ((mapHeight * mapScale) / 100) * token.position_y - (markerSize / 2),
																left: ((mapWidth * mapScale) / 100) * token.position_x - (markerSize / 2)
															}} />
														</Link>
											)
										})
									}
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
}
Map.contextType = GameContext;