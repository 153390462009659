import React, { Component } from 'react'
import { getLocalStorageItem } from '../../helpers/helpers';
import { Link } from 'react-router-dom';
import cup from '../map/assets/completed-popup.svg';

export default class GameDonePopup extends Component {
	onClose = e => {
		this.props.onClose && this.props.onClose(e)
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}

	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " hint-popup-container"} style={{ top: '50px' }} >
				<div className="h-p-box-container">
					<div className="hpb-inner-container">
						{/* Close Button */}
						<div className="hpb-close-button"> </div>
						{/* Body Section */}
						<div className="hpb-body-container">
							{/* Title */}
							<div className="hpdb-inner-container hpdb-title-container">
								{/* {game.currentBuilding}  */}
								YOU DID IT!
							</div>
							{/* image */}
							<div className="hpdb-inner-container hpdb-image-container">
								<div className="hpdbi-image">
									<img src={cup} alt='cup'/>
								</div>
							</div>
							{/* Description */}
							<div className="hpdb-inner-container hpdb-description-container">
								You completed all of the activities! It's time to check your score..
							</div>
							{/* Button */}
							<div className="hpdb-inner-container hpdb-button-container">
								<Link to="/leaderboard" className="hpdb-button">
									OK
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}