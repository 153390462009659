import React, { Component } from 'react';
import './certificate.css';
import AlfieImg from '../../assets/alfie-flag.png';
import AmeliaImg from '../../assets/amelia-flag.png'
import logoAB from './assets/ABlogo.svg';
import confetti from './assets/confetti.svg';
import { getLocalStorageItem } from '../../helpers/helpers';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

class Certificate extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}


	}

	generatePDF() {

		const quality = 1 // Higher the better but larger file

		html2canvas(document.querySelector('.pdf'),
			{ scale: quality }
		).then(canvas => {
			const pdf = new jsPDF('p', 'mm', 'a5');
			pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 10, 149 - 20, 210 - 20);
			pdf.save("certificate.pdf");
		}
		);

	}

	render() {

		return (
			<div className="certificate-container">
				<div className="certificate-box-container">
					<div className="confetti-container">
						<img src={confetti} alt='confetti'></img>
					</div>
					{/* Character Image */}
					<div className="pdf">
						<div className="image-container">
							{((getLocalStorageItem("character") === 'amelia') && (<img style={{ width: '40%' }} src={AmeliaImg} alt="Amelia" />)) ||
								((getLocalStorageItem("character") === 'alfie') && (<img style={{ width: '40%' }} src={AlfieImg} alt="Alfie" />)) ||
								((getLocalStorageItem("character") === undefined) && (<img style={{ width: '40%' }} src={AlfieImg} alt="Alfie" />))}
						</div>

						<div className="certificate-title boldtext">
							CERTIFICATE
							<div className="certificate-title">
								OF COMPLETION
							</div>
						</div>
						<div className="certificate-description">
							<div className="certificate-desc">
								Presented to:
								<div className="boldtext space"> {getLocalStorageItem("player")}</div>
							</div>
							<div className="certificate-desc">
								For finishing Aerospace adventure <br/> with a score of
								<div className="boldtext space">{getLocalStorageItem("score")} points!</div>
							</div>
							<div className="certificate-desc">Current ranking position {getLocalStorageItem("playerPosition")}</div>
						</div>
						{/* AB Image */}
						<div className="image-container">
							<img src={logoAB} alt="logo AB" />
						</div>
					</div>
				</div>

				{/* Button Section */}

				<div className="l-button-container">
					{/* Finish Button */}
					<div className="button lb-save-button" onClick={this.generatePDF}>
						SAVE
					</div>
					{/* Finish Button */}
					<a className="lb-button lb-play-button" href="/" >
						Play Again
					</a>
				</div>
			</div>

		);
	}
}

export default Certificate;
