import React from "react";
import { Link } from "react-router-dom";
import concorde from '../../images/concorde.svg';
import plane from '../../images/aerospace-plane.svg';
import './style.css';
import CloseButton from '../../components/quiz/assets/16/icon.png';
//import abd from '../../components/quiz/assets/16/icon.png'
import { getLocalStorageItem } from '../../helpers/helpers';

function renderSwitch(param) {
    switch (param) {
        case "small": return 'class-small';
        case "large": return 'class-large';
        default: return 'class-medium';
    }
}
const Location = props => {
    if(!props.show) {
		return null
	}

    // turn objects into array for child element use
    const currentZone = props.currentZone
    const mainList = Object.entries(props.mainZones)
    const concordeList = Object.entries(props.concordeZones)

    if(currentZone !== "concorde-1"){
        return (
            <div className={renderSwitch(getLocalStorageItem("fontSize")) +" hint-popup-container"} style={{position: "fixed"}}>
                <div className="h-p-box-container location-popup" style={{ position:'relative', margin:'auto', zIndex:'100', width:'95%', height:'70%' }}>
					<div className="hpb-inner-container popup-inner-container" style={{ overflow: "scroll", margin: "auto"}}>
                    <div className="hpb-close-button" style={{paddingTop: "15px"}}>
                        <Link to={"/game-menu?era=" + currentZone.match(/\d+/)[0]} className={'location-close'} onClick={props.onClose}>
                            <img src={CloseButton} alt="Close Menu" onClick={props.onClose}/>
                        </Link>
                    </div>
                        <div className="popup-content">
                            CHANGE LOCATION:
                        </div>

                    {mainList.map(function(zone) {

                        if(zone[0] === currentZone)
                        {
                            return (
                                <Link to={"/game-menu?era=" + zone[0].match(/\d+/)[0]} key={"/game-menu?era=" + zone[0].match(/\d+/)[0]} className={'era currentZone'} onClick={props.onClose}>
                                <h3>{'You are in ' + zone[1]['name'] + ':'}</h3>
                                <p>{zone[1]['description']}</p>
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={"/game-menu?era=" + zone[0].match(/\d+/)[0]} key={"/game-menu?era=" + zone[0].match(/\d+/)[0]} className={'era ' + zone[0]} style={{backgroundColor: zone[1]['colour']}} onClick={props.onClose}>
                                <h3>{'Go to ' + zone[1]['name'] + ':'}</h3>
                                <p>{zone[1]['description']}</p>
                                </Link>
                            )
                        }

                    })}

                    {/* use match to pull number out of index */}

                    {concordeList.map(zone => <Link to={"/game-menu?era=concorde-1"} key={"/game-menu?era=concorde-1"} className={'era concorde-hangar ' + zone[0]} style={{backgroundColor: zone[1]['colour']}} onClick={props.onClose}>
                        <div className={'map-nav__title'}>
                            <h3>{'Go to ' + zone[1]['name'] + ':'}</h3>
                        </div>
                        <div className={'map-nav__img'}><img src={concorde} alt="concorde" /></div>
                    </Link>)}

                    </div>
                </div>
            </div>
        )
    } else {
        return (
			<div className={renderSwitch(getLocalStorageItem("fontSize")) +" location-pin hint-popup-container"} style={{position: "fixed"}}>
			<div className="h-p-box-container location-popup" style={{ position:'relative', margin:'auto', zIndex:'100', width:'95%', height:'70%' }}>
				<div className="hpb-inner-container popup-inner-container" style={{ overflow: "scroll", margin: "auto"}}>
				<div className="hpb-close-button" style={{paddingTop: "15px"}}>
                            <Link to={"/game-menu?era=" + currentZone} key={"/game-menu?era=" + currentZone} className={'location-close'} >
                                <img src={CloseButton} alt="Close Menu" onClick={props.onClose}/>
                            </Link>
                        </div>
                        <div className="hpb-close-button">
                            <Link to={"/game-menu?era=concorde-1"} key={"/game-menu?era=concorde-1"} onClick={props.onClose}></Link>
                            </div>
                        <div className="popup-content">
                            CHANGE LOCATION:
                        </div>
                        <Link to={"/game-menu?era=1"} key={"/game-menu?era=1"} className={'era era-1'} onClick={props.onClose}>
                            <div className={'map-nav__title'}><h3>Go to Aerospace Galleries</h3></div>
                            <div className={'map-nav__img'}><img src={plane} alt="aerospace plane"></img></div>
                        </Link>
                        <Link to={"/game-menu?era=concorde-1"} key={"/game-menu?era=concorde-1"} className={'era concorde-1'} onClick={props.onClose}>
                            <div className={'map-nav__title'}><h3>{'You are in Concorde Hangar'}</h3></div>
                            <div className={'map-nav__img'}><img src={concorde} alt="concorde" /></div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
export default Location