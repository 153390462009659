import React, { Component } from 'react'
import { getLocalStorageItem } from '../../helpers/helpers';


export default class CameraPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			CameraPopup: false,
		}
	}

	onClose = e => {
		this.props.onClose && this.props.onClose(e)
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}

	toggleCameraPopup() {
		this.setState({
			CameraPopup: !this.state.CameraPopup,
		})
	}

	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " hint-popup-container"} style={{ top: '50px' }}>
				<div className="h-p-box-container">
					<div className="hpb-inner-container">
						<div className="hpb-close-button">
						</div>
						{/* Body Section */}
						<div className="hpb-body-container">
							{/* Title */}
							<div className="hpdb-inner-container hpdb-title-container" style={{color: '#000'}}>
								OOPS!!!
							</div>
							{/* Description */}
							{
								<div className="hpdb-inner-container hpdb-description-container" style={{color: '#000'}}>
									{this.props.description}
								</div>
							}
							{/* Button */}
							<div className="hpdb-inner-container hpdb-button-container">
								<button className="hpdb-button" onClick={e => { this.onClose(e) }}> OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}