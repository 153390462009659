import React, { Component } from 'react'; import './recap.css';
import axios from "axios";
import { Link } from "react-router-dom";
import BackExit from "../../components/backButton/backExit";
import amelia from "../../assets/reward-avatar-amelia.svg";
import alfie from "../../assets/reward-avatar-alfie.svg";
import { Redirect } from 'react-router-dom';
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/helpers';


// Question Screen
class RecapQuestion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// score: 0,
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		e.preventDefault(); //Prevents full refresh
		this.props.onChoiceChange(e.target.value);

	}

	render() {
		const question = this.props.question; // Variable set to equal question from props

		if (typeof (question) == "undefined") return null;
		return (
			<div>

				{/* Question Layout */}
				<div className="question-component">
					{question.content}
				</div>
				<div className="answer-container">
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_1'
						className="answer-btn"
					>
						{question.option_1}
					</button>
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_2'
						className="answer-btn"
					>
						{question.option_2}
					</button>
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_3'
						className="answer-btn"
					>
						{question.option_3}
					</button>
					<button
						type="submit"
						onClick={this.handleChange}
						name=''
						value='option_4'
						className="answer-btn"
					>
						{question.option_4}
					</button>
				</div>
			</div>
		)
	}
}

// Results Screen
class RecapResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			overallScore: 0,
			usersDetails: '',
			redirect: false
		}
	}

	overallScore = () => {
		this.setState((prevState, props) => ({
			overallScore: (prevState.overallScore + props.score)
		}))
	}

	handleSubmit = (event) => {
		setLocalStorageItem("score", getLocalStorageItem("score") + Number(this.props.score));
        setLocalStorageItem("recapDone", true);
		const params = new URLSearchParams();
		params.append('uuid', getLocalStorageItem("playerUuid"));
		params.append('score', this.props.score);

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER_DOMAIN_MAIN + 'data/save-new-score',
			data: params
		}).then((response) => {
			this.setState({
				data: response.data,
				redirect: true
			});
		})
			.catch(err => console.log(err))
	}

	componentDidMount() {
		this.overallScore()
	}

	render() {
		const { redirect } = this.state;
		if (redirect) {
			return <Redirect to='/leaderboard' />;
		}
		return (
			<div className="results-absolute">
				<div className="results-container">
					<div className="results-cont results-img">
						{((getLocalStorageItem("character") === 'amelia') && (<img src={amelia} alt="User" />)) ||
							((getLocalStorageItem("character") === 'alfie') && (<img src={alfie} alt="User" />)) ||
							((getLocalStorageItem("character") === undefined) && (<img src={alfie} alt="User" />))}
					</div>

					<div className="results-cont results-text" style={{
						'display': 'flex',
						'flex-direction': 'column', 'align-items': 'center', 'padding': '30px 0'
					}}>
						<h3>+{this.state.overallScore} points!</h3>
						<h4>Congratulations!</h4>
						<h5>correct answers </h5>
						<h5>{this.props.nbCorrectAnswers}/3</h5>
					</div>

					<div className="results-cont results-link">
						<Link
							className="quiz-return-btn"
							onClick={this.handleSubmit}
						>
							<h4>OK</h4>
						</Link>
					</div>
				</div>
			</div>
		)
	}
}

// Recap Section
class Recap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			score: 0,
			current: 0,
			data: { rows: {} },
			nbCorrectAnswers: 0
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(choice) {
		console.log('choice', choice);
		console.log('correct answer', this.props.questions[this.state.current].option_correct.key);
		this.setState((prevState, props) => ({
			current: prevState.current + 1,
			nbCorrectAnswers: choice === this.props.questions[this.state.current].option_correct.key ? this.state.nbCorrectAnswers + 1 : this.state.nbCorrectAnswers,
			score: choice === this.props.questions[this.state.current].option_correct.key ? prevState.score + 50 : prevState.score,
		}));
		console.log('nbCorrectAnswers', this.state.nbCorrectAnswers);
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}
	render() {
		const questions = this.props.questions;

		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " quiz-container"}>
				<BackExit id="backButton" />

				{/* Results */}
				{this.state.current >= 3 &&
					<RecapResults
						score={this.state.score}
						overallScore={this.state.overallScore}
						nbCorrectAnswers={this.state.nbCorrectAnswers}
					/>
				}

				{/* Question */}
				{this.state.current < 3 &&
					<RecapQuestion
						question={questions[this.state.current]}
						onChoiceChange={this.handleChange}
					/>
				}

			</div>
		);
	}
}

export default class RecapQuiz extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: { rows: {} },
		};
	}
	componentDidMount() {
		console.log('here is componentDidMount');
		axios.get(process.env.REACT_APP_SERVER_DOMAIN_MAIN + "data/get-random-questions", {
		})
			.then((response) => {
				this.setState({ data: response.data });
				console.log('random questions', response.data);
			})
			.catch(err => console.log(err))

	}
	render() {
		const data = this.state.data;
		const randomQuestions = data.rows;
		// const randomQuestion = questions[Math.floor(Math.random() * questions.length

		console.log('random questions data', randomQuestions)
		return (
			<div>
				<Recap questions={randomQuestions} />
			</div>
		)
	}
}
