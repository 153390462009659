import React, { Component } from 'react'
import { getLocalStorageItem } from '../../helpers/helpers';
import concordePopup from '../map/assets/concordePop-up.svg';
import { Link } from 'react-router-dom';

export default class ConcordePopup extends Component {
	onClose = e => {
		this.props.onClose && this.props.onClose(e)
	}

	renderSwitch(param) {
		switch (param) {
			case "small": return 'class-small';
			case "large": return 'class-large';
			default: return 'class-medium';
		}
	}

	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<div className={this.renderSwitch(getLocalStorageItem("fontSize")) + " hint-popup-container"} style={{ top: '50px' }}>
				<div className="h-p-box-container">
					<div className="hpb-inner-container">
						<div className="hpb-close-button">
						</div>
						{/* Body Section */}
						<div className="hpb-body-container">
							{/* Title */}
							<div className="hpdb-inner-container hpdb-title-container">
								CONCORDE TIME!
							</div>
							{/* Image */}
							{
								<div className="hpdb-inner-container hpdb-image-container">
									<div className="hpdbi-image">
										<img src={concordePopup} alt='' />
									</div>
								</div>
							}
							{/* Description */}
							{
								<div className="hpdb-inner-container hpdb-description-container">
									It's time to see the amazing Concorde!
									<br></br>
									Headover to the the Concorde Hangar  to continue your adventure.
								</div>
							}
							{/* Button */}
							<div className="hpdb-inner-container hpdb-button-container">
								<button className="hpdb-button" onClick={e => { this.onClose(e) }}>
									<Link to={"/game-menu?era=concorde-1"} style={{ color: 'white' }}>
										OK
									</Link>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}